import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {ReactComponent as EditIcon} from '../../../assets/icons/edit-icon.svg'
import clsx from 'clsx'
import {colors} from '../../../styles/colors'

const styles = {
  Root: {
    background: "none",
    border: "none",
    cursor: "pointer",
    height: "36px",
    display: "flex",
    alignItems: "center",
  },
  Icon: {
    marginLeft: "13px",
    display: "inline-block",
    height: "36px",
    width: "36px",
    padding: "6px",
    borderRadius: "5px",
    background: colors.gray9,
  },
  IconActive: {
    background: colors.red
  }
}
const useStyles = makeStyles(theme => styles)

export default function LkEditButton(props) {
  const classes = useStyles(props)

  return (
    <button className={classes.Root}>
      Редактировать
      <span className={clsx(classes.Icon, { [classes.IconActive]: props.active })}>
        <EditIcon />
      </span>
    </button>
  )
}