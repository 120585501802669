import React from 'react';
import { colors } from '../styles/colors';
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as TopLine } from '../assets/icons/top-line.svg'
import {getClasses} from '../helpers/mergeStylesHelper'

const centerWidth = 404
const styles = {
  Root: {
    height: 33,
    width: '100%',
    display: 'flex',
  },
  LineBottom: {
    transform: 'rotate(180deg)'
  },
  SideLine: {
    width: `calc(50% - ${centerWidth / 2}px)`,
    height: '100%',
    borderTop: `3px solid ${colors.orange}`
  },
  Center: {
    width: centerWidth,
    height: '100%',
    '& svg': {
      width: '100%',
    },
    '& path + path': {
      fill: 'black',
      fillOpacity: 0.8
    }
  },
  CenterTransparent: {
    '& path + path': {
      fill: 'transparent',
      fillOpacity: 0
    }
  }
}
const useStyles =  makeStyles(theme => styles);

export default function SideLine(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)

  const lineClasses = clsx(
    classes.Root,
    { [classes.LineBottom]: props.bottom }
  );

  const centerClasses = clsx(
    classes.Center,
    { [classes.CenterTransparent]: props.transparent }
  )

  return (
    <div className={lineClasses}>
      <div className={classes.SideLine} />
      <div className={centerClasses}>
        <TopLine />
      </div>
      <div className={classes.SideLine} />
    </div>
  )
}
