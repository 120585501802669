import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import LkHeader from '../../lk/LkHeader'
import LkHeaderAppend from '../../lk/LkHeaderAppend'
import LkBody from '../../lk/LkBody'
import LkMessage from './LkMessage'
import LkHeaderWorkStatus from '../../lk/LkHeaderWorkStatus'
import Lk from '../../lk/Lk'

const styles = {
  Root: {},
  Body: {
    position: "relative",
    padding: "66px",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}
const useStyles = makeStyles(theme => styles)

export default function LkMessages(props) {
  const classes = useStyles(props)
  const messages = [
    {
      index: 1,
      title: 'От АИС “Таксомотор”',
      text: 'Здравствуйте, Сергей Владимирович. Спасибо за регистрацию!',
      date: '02.12.2020'
    },
    {
      index: 2,
      title: 'От Курахина Маружиха Пелесовича',
      text: 'Здравствуйте, Сергей Владимирович. все в сили?',
      date: '02.12.2020'
    }
  ]

  return (
    <Lk>
      <LkHeader append={
        <LkHeaderAppend
          title={'Личный кабинет'}
          backLink={'/some'}
          rightSlot={<LkHeaderWorkStatus type={'start'} value={'Начать работу'}/>}
        />
      } />

      <LkBody activeTab={'message'}>
        <div className={classes.Body}>
          { messages.map((message, index) =>
            <LkMessage message={message} key={index} index={index} />
          )}
        </div>
      </LkBody>
    </Lk>
  )
}