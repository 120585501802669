import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Modal from '../Modal'
import TextField from '../TextField'
import Button from '../Button'
import {colors} from '../../styles/colors'
import {fontSizes} from '../../styles/font-sizes'
import {getClasses} from "../../helpers/mergeStylesHelper";
import CheckboxInput from "../CheckboxInput";


const styles = {
  Wrapper: {
    display: "flex",
    flexWrap: 'wrap',
    width: "100%"
  },
  OkButton: {
    margin: "30px 10px 10px",
    width: "calc(10% - 20px)"
  },
  Id: {
    color: colors.blue,
    textAlign: "center",
    margin: "10px 0 20px",
    fontSize: fontSizes.medium,
  },
  CloseButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "150px"
  },
  CheckboxTaxi: {
    padding: "10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: 'center',
    width: "27%",
    margin: "25px 0"
  },
  CheckboxMed: {
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    width: "33%",
    margin: "25px 0"
  },
  CheckboxTech: {
    padding: "10px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: 'center',
    width: "40%",
    margin: "25px 0"
  },
  CheckboxText: {
    color: colors.black,
    fontSize: fontSizes.small,
    marginBottom: 0
  },
  CheckboxWrapper: {
    color: colors.red
  },
}

const inputStyles = {
  Input: {
    margin: "10px",
  },
  InputCompany: {width: "calc(40% - 20px)"},
  InputAddress: {width: "calc(60% - 20px)"},
  InputINN1:{width: "calc(30% - 20px)"},
  InputINN2: {width: "calc(22% - 20px)"},
  InputINN3: {width: "calc(22% - 20px)"},
  InputPhone: {width: "calc(26% - 20px)"},
  InputPerson: {width: "calc(38% - 20px)"},
  InputSite: {width: "calc(35% - 20px)"},
  InputEmail: {width: "calc(27% - 20px)"},
  InputLicense: {width: "calc(45% - 20px)", marginTop: "30px"},
  InputEnterLicense: {width: "calc(45% - 30px)", marginTop: "30px"},
}

const modalStyles = {
  Body: {
    width: "873px",
    height: "auto",
    backgroundColor: colors.gray7,
  },
  Content: {
    display: "flex",
    flexWrap: 'wrap',
    flexDirection: "column",
    width: "100%"
  },
  Header: {color: colors.gray2},
  Buttons: {
    width: "100%",
  }
}

const useStyles = makeStyles(styles)
const useModalStyles = makeStyles(modalStyles)

export default function ConnectApplication(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)
  const modalClasses = useModalStyles()

  const getInputClass = (name) => {
    if (!name) return ''

    return makeStyles({
      root: {
        ...inputStyles.Input,
        ...(inputStyles[name] ? inputStyles[name] : {})
      }
    })()
  }

  return (
    <Modal
      classes={modalClasses}
      headerTextSlot={
        <span> Заявка на подключение к системе </span>
      }
      contentSlot={
        <React.Fragment>
          <div className={classes.Id}>
            ID 1234567
          </div>

          <div className={classes.Wrapper}>
            <TextField
              classes={getInputClass('InputCompany')}
              label="Организация"
              value="ООО 'Техносервис'"
              disabled
            />

            <TextField
              classes={getInputClass('InputAddress')}
              label="Адрес"
              value="123456 Москва, ул. Бориса Пастернака 35, кв. 305"
              disabled
            />

            <TextField
              classes={getInputClass('InputINN1')}
              label="ИНН"
              value="770112345678"
              disabled
            />

            <TextField
              classes={getInputClass('InputINN2')}
              label="ИНН"
              value="770112345678"
              disabled
            />

            <TextField
              classes={getInputClass('InputINN3')}
              label="ИНН"
              value="770112345678"
              disabled
            />

            <TextField
              classes={getInputClass('InputPhone')}
              label="Телефон"
              value="+7 499 1234567"
              disabled
            />

            <CheckboxInput
              checked
              disabled
              classes={{Root: classes.CheckboxTaxi, Text: classes.CheckboxText, InputWrapper: classes.CheckboxWrapper}}
            >
              Услуги такси
            </CheckboxInput>

            <CheckboxInput
              disabled
              classes={{Root: classes.CheckboxMed, Text: classes.CheckboxText, InputWrapper: classes.CheckboxWrapper}}
            >
              Проведение медосмотров
            </CheckboxInput>

            <CheckboxInput
              disabled
              classes={{Root: classes.CheckboxTech, Text: classes.CheckboxText, InputWrapper: classes.CheckboxWrapper}}
            >
              Проведение техосмотров
            </CheckboxInput>

            <TextField
              classes={getInputClass('InputPerson')}
              label="Ответственное лицо"
              value="Иванов Виктор Владимирович"
              disabled
            />

            <TextField
              classes={getInputClass('InputSite')}
              label="Веб-сайт"
              value="technos.ru"
              disabled
            />

            <TextField
              classes={getInputClass('InputEmail')}
              label="E-mail"
              value="info@technos.ru"
              disabled
            />

            <TextField
              classes={getInputClass('InputLicense')}
              label="Лицензия такси"
              value="МСК 062352 №061850"
              disabled
            />

            <TextField
              classes={getInputClass('InputEnterLicense')}
              label="Ввести вручную"
              disabled
            />

            <Button
              text="Ок"
              color="light-gray"
              className={classes.OkButton}
            />
          </div>
        </React.Fragment>
      }

      buttonsSlot={
        <div className={classes.CloseButton}>
          <Button
            text="Закрыть"
            color="blue"
            size="big"
          />
        </div>
      }
    />
  )
}

