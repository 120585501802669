import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {ReactComponent as FeedbackIcon} from '../../assets/icons/feedback.svg'
import {colors} from '../../styles/colors'
import Modal from '../Modal'
import Button from '../Button'

const styles = {
  FeedbackIcon: { marginBottom: "39px" },
  FeedbackText: {
    resize: "none",
    outline: "none",
    width: "510px",
    height: "214px",
    padding: "10px",
    "@media (max-width: 768px)": { width: "100%" }
  }
}

const modalStyles = {
  Body: {
    backgroundColor: colors.orange,
    width: "647px",
    height: "702px",
  },
  Content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  Header: { color: colors.gray2 },
}

export default function SendMessageModal() {
  const classes = makeStyles(styles)()
  const modalClasses = makeStyles(modalStyles)()

  return (
    <Modal
      classes={modalClasses}
      headerTextSlot={
        <span>Отправить сообщение</span>
      }
      contentSlot={
        <React.Fragment>
          <FeedbackIcon className={classes.FeedbackIcon}/>
          <textarea className={classes.FeedbackText}/>
        </React.Fragment>
      }
      buttonsSlot={
        <Button
          text="Отправить"
          size="big"
          color="red"
        />
      }
    />
  )
}