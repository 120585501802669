import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {colors} from '../../../styles/colors'
import {fontSizes} from '../../../styles/font-sizes'
import TextField from '../../TextField'
import Button from '../../Button'
import {AuthFormStyles, AuthFormStylesTextFields} from '../../../styles/sharable'
import {fontFamily} from '../../../styles/font-family'
import Select from '../../Select'
import CheckboxInput from '../../CheckboxInput'

const useStyles = makeStyles({
  ...AuthFormStyles,
  Body: {
    ...AuthFormStyles.Body,
    paddingBottom: 23
  },
  Terms: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingBottom: "20px",
    color: colors.white
  },
  TermsLink: {
    fontSize: fontSizes.small,
    color: colors.darkorange,
    fontFamily: fontFamily.light,
    marginLeft: "36px"
  },
  Form: {
    backgroundColor: colors.bgTransparent,
    width: "370px",
    height: "auto",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "28px 24px"
  },
  FormPassword: {
    marginBottom: 23
  },
  FormEnter: {
    marginTop: "44px"
  }
})

const buttonStyles = {
  Root: {
    marginTop: 20
  }
}
const useTextFieldStyles = makeStyles({
  ...AuthFormStylesTextFields,
  marginTop: { marginTop: 20 }
})
const useButtonStyles = makeStyles(buttonStyles)

export default function RegistrationForm(props) {
  const classes = useStyles(props)
  const textFieldClasses = useTextFieldStyles(props)
  const buttonClasses = useButtonStyles(props)

  return (
    <div className={classes.Root}>
      <form className={classes.Body}>
        <TextField
          name="Телефон"
          label="Телефон"
          type="tel"
          classes={textFieldClasses}
          inputId="reg-tel"
          required
        />

        <TextField
          name="Пароль"
          label="Пароль"
          type="password"
          classes={textFieldClasses}
          className={textFieldClasses.marginTop}
          inputId="reg-pass"
          required
        />

        <TextField
          name="Повторите пароль"
          label="Повторите пароль"
          type="password"
          classes={textFieldClasses}
          className={textFieldClasses.marginTop}
          inputId="reg-pass-repeat"
          required
        />

        <Select
          input-id="reg-select"
          className={textFieldClasses.marginTop}
          options={[
            { id: 1, name: 'Водитель 1' },
            { id: 2, name: 'Водитель 2' },
            { id: 3, name: 'Водитель 3' },
            { id: 4, name: 'Водитель 4' }
          ]}
          required
          classes={textFieldClasses}
        />

        <Button
          color="orange"
          classes={buttonClasses}
          size="big"
          text="Регистрация"
        />
      </form>

      <div className={classes.Terms}>
        <CheckboxInput>
          Я принимаю условия Пользовательского соглашения и даю своё согласие на обработку моей персональной информации
        </CheckboxInput>

        <a className={classes.TermsLink} href="/terms">
          Пользовательское соглашение
        </a>
      </div>
    </div>
  )
}
