import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import TextField from './TextField'
import {checkboxInFieldSet, fieldSetStyles} from '../styles/sharable'
import Select from "./Select";
import {getClasses} from "../helpers/mergeStylesHelper"
import CheckboxInput from './CheckboxInput'
import AvatarBlock from './AvatarBlock'
import {getInputClass as getInputClassHelper} from '../helpers/getInputClass'

const styles = {
  ...fieldSetStyles,
  Root: {
    width: "100%",
    flexWrap: "wrap",
    alignContent: "flex-start",
    display: "flex",
    justifyContent: "flex-start",
  },
  TopWrapper: {
    display: "flex",
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: "100%"
  },
  MiddleWrapper: {
    width: "100%",
    display: "flex",
    marginTop: "10px"
  },
  BottomWrapper: {
    width: "100%",
  },
  AvatarBlock: {
    display: "flex",
    flexDirection: "column",
    width: 165,
  },
  AvatarPhotoWrapper: {
    width: 165,
    height: 165
  },
  PassportDetails: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    width: "80%",
    marginTop: -10,
    alignItems: 'flex-start',
    alignContent: 'flex-start'
  },
}

const checkboxStyles = checkboxInFieldSet

const inputStyles = {
  Input: {
    margin: "10px",
  },
  InputCompany: {flexBasis: "300px"},
  InputPosition: {flexBasis: "155px"},
  InputStatus: {flexBasis: "140px"},
  InputLogin: {flexBasis: "155px"},
  InputName: {flexBasis: "145px"},
  InputSecondname: {flexBasis: "185px"},
  InputSurname: {flexBasis: "180px"},
  InputPassport: {flexBasis: "143px"},
  InputPassDate: {flexBasis: "132px"},
  InputPassGetBy: {flexBasis: "232px"},
  InputINN: {flexBasis: "144px"},
  InputAddress: {flexBasis: "383px"},
  InputPhone: {flexBasis: "177px"},
  InputEmail: {flexBasis: "188px"},
}

const useStyles = makeStyles(styles)
const useCheckboxStyles = makeStyles(checkboxStyles)

export default function EmployeeCard(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)
  const checkboxClasses = useCheckboxStyles()
  const getInputClass = name => getInputClassHelper(name, inputStyles)

  return (
    <div className={classes.Root}>
      <div className={classes.TopWrapper}>
        <TextField
          classes={getInputClass('InputLogin')}
          label="Логин"
          placeholder={'Иван'}
        />

        <TextField
          classes={getInputClass('InputName')}
          label="Имя"
        />

        <TextField
          classes={getInputClass('InputSecondname')}
          label="Отчество"
        />

        <TextField
          classes={getInputClass('InputSurname')}
          label="Фамилия"
        />

        <Select
          classes={getInputClass('InputPosition')}
          fullWidth
          label="Позиция"
        />

        <Select
          classes={getInputClass('InputStatus')}
          fullWidth
          label="Статус"
        />

        <TextField
          classes={getInputClass('InputPhone')}
          label="Номер телефона"
          placeholder={'+7 999 9999999'}
        />

        <TextField
          classes={getInputClass('InputEmail')}
          label="E-mail"
        />
      </div>

      <div className={classes.MiddleWrapper}>
        <AvatarBlock
          className={classes.AvatarBlock}
          classes={{ PhotoWrapper: classes.AvatarPhotoWrapper }}
        />

        <div className={classes.PassportDetails}>
          <TextField
            classes={getInputClass('InputPassport')}
            label="Паспорт"
            placeholder={'1111 111111'}
          />
          <TextField
            classes={getInputClass('InputPassDate')}
            type={'date'}
            label="Дата выдачи"
          />
          <TextField
            classes={getInputClass('InputPassGetBy')}
            label="Кем выдан"
          />
          <TextField
            classes={getInputClass('InputINN')}
            label="ИНН"
          />
          <TextField
            classes={getInputClass('InputAddress')}
            label="Адрес прописки"
          />

          { props.showOrganization &&
          <Select
            classes={getInputClass('InputCompany')}
            fullWidth
            options={[
              { id: 1, name: 'Опция 1' },
              { id: 2, name: 'Опция 2' },
            ]}
            label="Организация"
          />
          }
        </div>
      </div>

      <div className={classes.BottomWrapper}>
        <fieldset className={classes.Fieldset}>
          <legend className={classes.FieldsetLabel}>Полномочия</legend>
          <div className={classes.FieldsetBody}>
            <CheckboxInput classes={checkboxClasses}>
              Управление пользователем
            </CheckboxInput>

            <CheckboxInput classes={checkboxClasses}>
              Выдача путевых листов
            </CheckboxInput>

            <CheckboxInput classes={checkboxClasses}>
              Управление транспортными средствами
            </CheckboxInput>
          </div>
        </fieldset>
      </div>
    </div>
  )
}
