import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Video from '../../../assets/videos/registration.mp4'
import LoginHeader from '../login/LoginHeader'
import LoginFooter from '../login/LoginFooter'
import RegistrationForm from './RegistrationForm'
import { AuthPageStyles } from '../../../styles/sharable'

const useStyles = makeStyles(AuthPageStyles)

export default function LoadPageFooter(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.Root}>
      <div className={classes.Body}>
        <LoginHeader />
        <RegistrationForm />
        <LoginFooter />
      </div>

      <div className={classes.Shadow} />

      <video autoPlay muted loop id="myVideo" className={classes.Video}>
        <source src={Video} type="video/mp4" />
      </video>
    </div>
  )
}
