import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {fontSizes} from '../../../styles/font-sizes'
import LkHeader from '../../lk/LkHeader'
import LkHeaderAppend from '../../lk/LkHeaderAppend'
import clsx from 'clsx'
import {ReactComponent as RightArrowIcon} from '../../../assets/icons/arrow-right.svg'
import {ReactComponent as LeftArrowIcon} from '../../../assets/icons/arrow-left.svg'
import {ReactComponent as BasketIcon} from '../../../assets/icons/basket.svg'
import {SlideArrow} from '../../../styles/sharable'
import LkBody from '../../lk/LkBody'
import Button from '../../Button'
import Select from '../../Select'
import TextField from '../../TextField'
import Lk from '../../lk/Lk'

const styles = {
  Root: {},
  BodyImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    height: "70%",
    marginTop: "50px",
    '& div': {
      '& img': {
        objectFit: "cover",
        maxWidth: "720px",
        width: "100%",
        height: "auto"
      }
    }
  },
  DocInfo: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    marginTop: "60px",
    "& input, & select, & label": {
      fontSize: `${fontSizes.small} !important`
    }
  },
  DocInfoWrapper: {
    display: "flex",
    justifyContent: "center"
  },
  DocInfoEl: {
    marginLeft: "15px",
    marginTop: "10px"
  },
  DeleteDoc: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: fontSizes.small,
    marginTop: "65px",
    cursor: "pointer"
  },
  DeleteDocIcon: {
    marginBottom: "15px"
  }
}

const lkBodyStyles = {
  Root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "calc(100% - 150px)"
  },
}

const saveButtonStyles = {
  Root: {
    marginTop: "50px",
    marginBottom: "59px"
  },
}

const slideArrowStyles = {
  ...SlideArrow,
  Root: {
    ...SlideArrow.Root,
    position: 'fixed'
  }
}

const useStyles = makeStyles(styles)

export default function LkViewDoc(props) {
  const classes = useStyles(props)
  const saveButtonClasses = makeStyles(saveButtonStyles)()
  const slideArrowClasses = makeStyles(slideArrowStyles)()
  const lkBodyClasses = makeStyles(lkBodyStyles)()

  const doc = {
    img: require('../../../assets/images/scan1Big.png'),
    title: 'Водительское удостоверение',
    number: 'ПЛ-148753',
    dateFrom: '01.01.2019',
    dateTo: '01.01.2029'
  }

  return (
    <Lk>
      <LkHeader append={
        <LkHeaderAppend title={doc.title} backLink={'/some'} />
      } />

      <button className={clsx(slideArrowClasses.Right, slideArrowClasses.Root)}>
        <RightArrowIcon />
      </button>

      <button className={clsx(slideArrowClasses.Left, slideArrowClasses.Root)}>
        <LeftArrowIcon />
      </button>

      <LkBody classes={lkBodyClasses}>
        <div className={classes.BodyImg}>
          <div>
            <img src={doc.img} alt="document" />
          </div>
        </div>

        <div className={classes.DocInfoWrapper}>
          <div className={classes.DocInfo}>
            <div className={classes.DocInfoEl}>
              <Select
                label={'Тип документа'}
                classes
                selectedId={2}
                options={[ { id: 1, name: 'Медицинская справка' }, { id: 2, name: 'Водительское удостоверение' } ]}
              />
            </div>

            <div className={classes.DocInfoEl}>
              <TextField
                label="Номер"
                width="150px"
              />
            </div>

            <div className={classes.DocInfoEl}>
              <TextField
                type={'date'}
                label="Дата выдачи"
                width="166px"
              />
            </div>

            <div className={classes.DocInfoEl}>
              <TextField
                type={'date'}
                label="Действителен до"
                width="166px"
              />
            </div>
          </div>
        </div>

        <Button
          text={'Сохранить'}
          size={'big'}
          color={'blue'}
          classes={saveButtonClasses}
        />
      </LkBody>

      <div className={classes.DeleteDoc}>
        <BasketIcon className={classes.DeleteDocIcon} />
        <span>
          Удалить документ
        </span>
      </div>
    </Lk>
  )
}