import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {getClasses} from '../../helpers/mergeStylesHelper'
import Lk from '../lk/Lk'
import LkHeader from '../lk/LkHeader'
import LkHeaderAppend from '../lk/LkHeaderAppend'
import LkBody from '../lk/LkBody'
import EntityList from '../EntityList'
import {colors} from '../../styles/colors'


const styles = {
  Root: {},
  CheckBoxText: {
    color: colors.black
  },
}
const useStyles = makeStyles(styles)

export default function LkWedmorkerActiveRequestsHistory(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)

  const tableData = [
    {
      date: '01.01.2020',
      request_number: '0001',
      pl_number: 'ПЛ-0001',
      fio: 'Иванов Иван Иванович',
      completed: true
    },
    {
      date: '01.01.2020',
      request_number: '0001',
      pl_number: 'ПЛ-0001',
      fio: 'Иванов Иван Иванович',
      completed: true
    },
    {
      date: '01.01.2020',
      request_number: '0001',
      pl_number: 'ПЛ-0001',
      fio: 'Иванов Иван Иванович',
      completed: true
    },
    {
      date: '01.01.2020',
      request_number: '0001',
      pl_number: 'ПЛ-0001',
      fio: 'Иванов Иван Иванович',
      completed: true
    },
  ]

  return (
    <Lk classes={classes}>
      <LkHeader title={'медработник'} append={
        <LkHeaderAppend
          title='Личный кабинет'
          backLink={'/some'}
        />
      } />

      <LkBody activeTab={'history'} type={'other'}>
        <EntityList
          topToolbarSearchWidth={'100%'}
          data={tableData}
          addButtonVisibility={false}
          toolbarButtonText={'Добавить ТС'}
          toolbarLeftButtonClassName={classes.LeftButtonToolbar}
          columns={[
            {title: 'Дата', field: 'date', align: 'center'},
            {title: 'Номер заявки', field: 'request_number', align: 'center'},
            {title: 'Номер ПЛ', field: 'pl_number', align: 'center'},
            {title: 'ФИО', field: 'fio', align: 'center'},
            {
              title: 'МО пройдено',
              field: 'delete',
              align: 'center',
              render: () => (
                <span>в допуске отказано</span>
              )
            },
          ]}
        />
      </LkBody>
    </Lk>
  )
}