import React from 'react'
import { media } from '../../styles/media'
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles'
import SideLine from '../SideLine'
import HeaderLogo from './HeaderLogo'
import {getClasses} from '../../helpers/mergeStylesHelper'

const styles = {
  Header: {
    width: '100%',
  },

  Body: {
    height: 130,
    display: 'flex',
    justifyContent: 'space-between',
    background: 'rgba(0, 0, 0, 0.8)',
    padding: "0 35px",
    [media['768']]: {
      height: 'auto',
      flexWrap: 'wrap'
    },
  },

  BodyCenter: {},

  BodyBlock: {
    width: '33%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  BodyLeft: {
    justifyContent: 'flex-start',
  },

  BodyRight: {
    justifyContent: 'flex-end'
  },

  Append: {
    width: '100%'
  }
}

const useStyles =  makeStyles(styles);

export default function Header(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)

  return (
    <header className={classes.Header}>
      <div className={classes.Body}>
        <div className={clsx(classes.BodyLeft, classes.BodyBlock)}>
          {props.leftSlot}
        </div>

        <div className={clsx(classes.BodyCenter, classes.BodyBlock)}>
          {props.centerSlot || <HeaderLogo name={props.name || ''}/>}
        </div>

        <div className={clsx(classes.BodyRight, classes.BodyBlock)}>
          {props.rightSlot}
        </div>
      </div>

      <SideLine />

      <div className={classes.Append}>
        {props.append}
      </div>
    </header>
  )
}
