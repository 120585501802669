import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {getClasses} from '../../helpers/mergeStylesHelper'
import Lk from '../lk/Lk'
import LkHeader from '../lk/LkHeader'
import LkHeaderAppend from '../lk/LkHeaderAppend'
import LkBody from '../lk/LkBody'
import TextField from '../TextField'
import LkOperatorAnaliticsBlock from './LkOperatorAnaliticsBlock'
import {fontFamily} from '../../styles/font-family'
import clsx from 'clsx'


const styles = {
  Root: {},
  Body: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  Header: {
    width: '100%',
    marginBottom: 10
  },
  ListItem: {
    listStyle: 'none',
    '& span': {
      fontFamily: fontFamily.bold
    }
  },
  Third: {
    width: '30%'
  },
  TwoThird: {
    width: '60%'
  },
  LastDateInput: {
    marginLeft: 5
  },
  BlockWithoutLeftMargin: {
    marginLeft: 0
  }
}
const useStyles = makeStyles(styles)

export default function LkOperatorAnalitics(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)


  return (
    <Lk classes={classes}>
      <LkHeader title={'оператор'} append={
        <LkHeaderAppend
          title='Аналитические отчеты'
          backLink={'/some'}
        />
      } />

      <LkBody activeTab={'reports'} type={'analitics'} className={classes.Body}>
        <div className={classes.Header}>
          <TextField label={'Дата от'} type={'date'} />

          <TextField label={'до'} type={'date'} className={classes.LastDateInput} />
        </div>

        <LkOperatorAnaliticsBlock
          title={'Распределение участников системы по типу организационной формы'}
          className={clsx(classes.Third, classes.BlockWithoutLeftMargin)}
        >
          <img src={require('../../assets/images/analitics1.png')} alt="analictics 1" />
        </LkOperatorAnaliticsBlock>

        <LkOperatorAnaliticsBlock
          title={'Распределение участников системы по типу организационной формы'}
          className={classes.Third}
        >
          <img src={require('../../assets/images/analitics2.png')} alt="analitics 2" />
        </LkOperatorAnaliticsBlock>

        <LkOperatorAnaliticsBlock className={classes.Third}>
          <LkOperatorAnaliticsBlock title={'Популярные марки автомобилей у водителей:'} gray>
            <ul>
              <li className={classes.ListItem}>Renault Logan</li>
              <li className={classes.ListItem}>Scoda Octavia</li>
              <li className={classes.ListItem}>Nissan Almera</li>
              <li className={classes.ListItem}>Hyundai Solaris</li>
              <li className={classes.ListItem}>Volkswagen Polo</li>
            </ul>
          </LkOperatorAnaliticsBlock>

          <LkOperatorAnaliticsBlock title={'Популярные агрегаторы у водителей:'} gray>
            <ul>
              <li className={classes.ListItem}>Gett Taxi</li>
              <li className={classes.ListItem}>Uber</li>
              <li className={classes.ListItem}>Wheely</li>
              <li className={classes.ListItem}>Яндекс.Такси</li>
            </ul>
          </LkOperatorAnaliticsBlock>
        </LkOperatorAnaliticsBlock>

        <LkOperatorAnaliticsBlock
          title={'Распределение участников системы по типу организационной формы'}
          className={clsx(classes.TwoThird, classes.BlockWithoutLeftMargin)}
        >
          <ul>
            <li className={classes.ListItem}>
              Средний возраст водителей в системе – <span>38 лет</span>
            </li>

            <li className={classes.ListItem}>
              Среднее количество штрафов на водителя за год – <span>3</span>
            </li>

            <li className={classes.ListItem}>
              Среднее количество неоплаченных штрафов - <span>2</span>
            </li>

            <li className={classes.ListItem}>
              Средняя сумма штрафа – <span>700 р</span>
            </li>

            <li className={classes.ListItem}>
              Среднее количество агрегаторов на водителя - <span>2</span>
            </li>

            <li className={classes.ListItem}>
              Среднее количество водителей на организацию –  <span>22 человека</span>
            </li>

            <li className={classes.ListItem}>
              Среднее количество штрафов на организацию – <span>13</span>
            </li>

            <li className={classes.ListItem}>
              Среднее количество неоплаченных штрафов - <span>20</span>
            </li>

            <li className={classes.ListItem}>
              Средняя сумма штрафа – <span>1000 р</span>
            </li>

            <li className={classes.ListItem}>
              Средний возраст автомобилей:
              <br/>- для ИП – <span>4 года</span>
              <br/>- для юрлиц – <span>7 лет</span>
            </li>
          </ul>
        </LkOperatorAnaliticsBlock>
      </LkBody>
    </Lk>
  )
}