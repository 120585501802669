import React from 'react';
import * as PropTypes from 'prop-types';
import { colors } from '../styles/colors';
import { fontFamily } from '../styles/font-family';
import clsx from 'clsx';
import {makeStyles} from "@material-ui/core/styles";
import { ReactComponent as CheckBoxIcon } from '../assets/icons/checkbox.svg'
import {getClasses} from '../helpers/mergeStylesHelper'

const styles = {
  Root: {
    display: 'flex'
  },
  Input: {
    display: 'none'
  },
  InputWrapper: {},
  Text: {
    color: colors.white,
    fontSize: 11,
    fontFamily: fontFamily.light,
    lineHeight: '18px',
    marginBottom: 4,
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center'
  },
  Label: {},
  Icon: {
    cursor: 'pointer',
    '& path + path': {
      display: 'none'
    }
  },
  Active: {
    '& path + path': {
      display: 'initial'
    }
  }
}

const useStyles =  makeStyles(styles);

export default function CheckboxInput(props) {
  const classes = getClasses(props.classes, useStyles(props), styles);

  const iconClasses = clsx(
    classes.Icon,
    { [classes.Active]: props.value }
  );

  return (
    <div className={classes.Root}>
      <label htmlFor={props.id} className={classes.Label}>
        <span className={classes.InputWrapper}>
           <CheckBoxIcon className={iconClasses}/>
         </span>

        <input
          type="checkbox"
          id={props.id}
          className={classes.Input}
          checked={props.checked}
          disabled={props.disabled}
        />
      </label>

      <div className={classes.Text}>
        { props.children }
      </div>
    </div>
  )
}

CheckboxInput.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string
};

