import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {colors} from '../../styles/colors'
import {fontFamily} from '../../styles/font-family'
import {fontSizes} from '../../styles/font-sizes'
import {getClasses} from '../../helpers/mergeStylesHelper'
import {ReactComponent as EmailInputIcon} from '../../assets/icons/email.svg'
import Modal from '../Modal'
import CheckboxInput from '../CheckboxInput'
import TextField from '../TextField'
import Button from '../Button'

const styles = {
  Note: {
    color: colors.red,
    fontSize: fontSizes.medium,
    fontFamily: fontFamily.bold
  },
  Email: {
    color: colors.red,
    fontSize: fontSizes.medium
  },
  EmailIcon: {
    marginTop: "45px"
  },
  AddressBook: {
    marginTop: "20px"
  },
}

const modalStyles = {
  Body: {
    width: "873px",
    height: "702px",
    backgroundColor: colors.gray7,
  },
  Content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  HeadingContent: {
    position: "absolute"
  },
}

const inputStyles = {
  Root: { width: "auto" },
  Text: {
    marginLeft: "25px",
    marginTop: "5px",
    fontSize: fontSizes.small,
    fontFamily: fontFamily.bold,
    color: colors.gray2
  },
  Label: { color: colors.gray2 },
}

const useStyles = makeStyles(theme => styles)

export default function WayToGetIdModal(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)
  const modalClasses = makeStyles(modalStyles)()
  const inputClasses = makeStyles(inputStyles)()

  return (
    <Modal
      classes={modalClasses}
      headerTextSlot={
        <span>Способ получения идентификатора</span>
      }
      contentSlot={
        <React.Fragment>
          <div className={classes.Note}>
            Ваш ID будет выслан на e-mail:
          </div>

          <div className={classes.Email}>
            1332@mail.ru
          </div>

          <EmailInputIcon className={classes.EmailIcon} />

          <CheckboxInput classes={inputClasses}>
            Прислать пластиковую карту по месту жительства
          </CheckboxInput>

          <TextField
            type="text"
            input-id="modal-address-input"
            width="706px"
            placeholder="101001 г. Москва Красная пл. д.9"
            centered
            required
          />
        </ React.Fragment>
      }
      buttonsSlot={
        <Button
          text="Сохранить и продолжить"
          color="blue"
          size="big"
        />
      } />
  )
}