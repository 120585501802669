import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {getClasses} from '../../helpers/mergeStylesHelper'
import Lk from '../lk/Lk'
import LkHeader from '../lk/LkHeader'
import LkHeaderAppend from '../lk/LkHeaderAppend'
import LkBody from '../lk/LkBody'
import {colors} from '../../styles/colors'
import TextField from '../TextField'
import {getInputClass as getInputClassHelper} from '../../helpers/getInputClass'
import {inputStyles} from '../modals/TrustedOrganizationModal'
import Button from '../Button'


const styles = {
  Body: {
    display: "flex",
    width: "80%",
    justifyContent: 'center'
  },
  Wrapper: {
    display: "flex",
    width: "100%",
    maxWidth: 840,
    flexDirection: 'column'
  },
  BodyFields: {
    display: "flex",
    flexWrap: 'wrap',
    width: "100%",
  },
  Root: {},
  CheckBoxText: {
    color: colors.black
  },
  BodyBottom: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30
  },
  BottomButtons: {
    marginTop: 20
  },
}
const useStyles = makeStyles(styles)

export default function LkOperatorTaksoparkDetail(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)
  const getInputClass = name => getInputClassHelper(name, inputStyles)

  return (
    <Lk classes={classes}>
      <LkHeader title={'оператор таксопарка'} append={
        <LkHeaderAppend
          title='ООО "Таксопарк 22"'
          backLink={'/some'}
        />
      } />

      <LkBody className={classes.Body}>
        <div className={classes.Wrapper}>
          <div className={classes.BodyFields}>
            <TextField
              classes={getInputClass('InputFifty')}
              label="Полное наименование орг."
              value="ООО 'Техносервис'"
            />

            <TextField
              classes={getInputClass('InputFifty')}
              label="Краткое наименование организации"
              value="Техсервис"
            />

            <TextField
              classes={getInputClass('InputFifty')}
              label="Адрес местонахождения"
              value="ул. Бориса Пастернака 35, кв. 305"
            />

            <TextField
              classes={getInputClass('InputFifty')}
              label="Юридический адрес"
              value="123456 Москва, ул. Бориса Пастернака 35, кв. 305"
            />

            <TextField
              classes={getInputClass('InputINN1')}
              label="ОРГН"
              value="770112345678"
            />

            <TextField
              classes={getInputClass('InputINN2')}
              label="ИНН"
              value="770112345"
            />

            <TextField
              classes={getInputClass('InputINN3')}
              label="КПП"
              value="1234567"
            />

            <TextField
              classes={getInputClass('InputPhone')}
              label="Телефон"
              value="+7 499 1234567"
            />

            <TextField
              classes={getInputClass('InputPerson')}
              label="Генеральный директор"
              value="Иванов Виктор Владимирович"
            />

            <TextField
              classes={getInputClass('InputSite')}
              label="Веб-сайт"
              value="technos.ru"
            />

            <TextField
              classes={getInputClass('InputEmail')}
              label="E-mail"
              value="info@technos.ru"
            />

          </div>

          <div className={classes.BodyBottom}>
            <Button
              text="Сохранить"
              color="blue"
              size="big"
              className={classes.BottomButtons}
            />
          </div>
        </div>
      </LkBody>
    </Lk>
  )
}