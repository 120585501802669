import React from 'react'
import { fontFamily } from '../../styles/font-family'
import { colors } from '../../styles/colors'
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg'
import {makeStyles} from '@material-ui/core/styles'

const useStyles =  makeStyles(theme => ({
  Main: {
    marginTop: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start"
  },
  Text: {
    marginLeft: 19,
    color: colors.white,
    fontFamily: fontFamily.bold,
    textTransform: "uppercase",
    fontSize: '24px'
  },
  Image: {
    width: 53,
    height: 75
  },
  Name: {
    fontFamily: fontFamily.bold,
    fontSize: "10px",
    letterSpacing: "10px",
    color: colors.orange,
    textAlign: "center",
    marginBottom: 0
  }
}));

export default function HeaderLogo(props) {
  const classes = useStyles(props)

  return (
    <div>
      <div className={classes.Main}>
        <LogoIcon className={classes.Image} />
        <div className={classes.Text}>
          Контроль <br />
          работы такси
        </div>
      </div>

      <div className={classes.Name}>
        {props.name}
      </div>
    </div>
  )
}
