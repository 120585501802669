import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {colors} from '../styles/colors'
import {fontFamily} from '../styles/font-family'
import {fontSizes} from '../styles/font-sizes'
import {CenteredAbsolute} from '../styles/sharable'
import {getClasses} from '../helpers/mergeStylesHelper'
import {ReactComponent as CloseIcon} from '../assets/icons/close.svg'

const styles = {
  Root: {},
  Body: {
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 110,
    padding: "25px 25px 80px",
    borderRadius: "10px",
    overflowY: "auto",
  },
  Close: {
    width: "31px",
    height: "31px",
    backgroundColor: colors.white,
    border: `1px solid ${colors.black}`,
    borderRadius: "50px",
    position: "relative",
    float: "right",
    cursor: "pointer"
  },
  CloseIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "20px",
    height: "20px"
  },
  Container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "32px",
    height: "calc(100% - 33px)",
    padding: "0 33px"
  },
  Header: {
    textAlign: "center",
    "& span": {
      fontFamily: fontFamily.bold,
      fontSize: fontSizes.large
    }
  },
  Content: { margin: "20px 0 30px" },
  Buttons: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center"
  },
  Overlay: {
    ...CenteredAbsolute,
    position: "fixed",
    width: "100%",
    height: "100%",
    zIndex: 100,
    backgroundColor: colors.black,
    opacity: 0.5
  },
  HeadingContent: {}
}
const useStyles = makeStyles(theme => styles)

export default function Modal(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)

  return (
    <div>
      <div className={classes.Body}>
        <div className={classes.Close}>
          <CloseIcon className={classes.CloseIcon} />
        </div>

        <div className={classes.Container}>
          <div className={classes.HeadingContent}>
            {props.headerContentSlot}
          </div>

          <div className={classes.Header}>
            {props.headerTextSlot}
          </div>

          <div className={classes.Content}>
            {props.contentSlot}
          </div>

          <div className={classes.Buttons}>
            {props.buttonsSlot}
          </div>
        </div>
      </div>

      <div className={classes.Overlay} />
    </div>
  )
}