export const colors = {
  black: '#000',
  blue: '#0065b1',
  gray: '#2e2e2e',
  gray2: '#535353',
  gray3: '#787878',
  gray4: '#a9acaf',
  gray5: '#d6d6d6',
  gray6: '#e5e5e5',
  gray7: '#f3f3f3',
  gray8: '#7a7a7a',
  gray9: '#c4c4c4',
  gray10: '#f2f2f2',
  gray11: '#a8aaae',
  gray12: '#999999',
  gray13: '#444444',
  gray14: '#808080',
  gray15: '#e7e7e7',
  green: '#8ed000',
  green2: '#53C80C',
  orange: '#ffcc00',
  darkorange: '#f2c94c',
  red: '#ee3f58',
  red2: '#ed4a60',
  lightred: '#fe96a5',
  white: '#fff',
  yellowLight: '#ffeda6',
  borderShadow: 'rgba(0, 0, 0, 0.2)',
  borderColor: 'rgba(102, 102, 102, 0.24)',
  buttonBoxShadow1: 'rgba(50, 50, 71, 0.08)',
  buttonBoxShadow2: 'rgba(50, 50, 71, 0.06)',
  bgTransparent: 'rgba(0, 0, 0, 0.6)'
}
