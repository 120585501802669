import React from 'react'
import LkCard from '../../lk/LkCard'
import LkTransport from './LkTransport'
import LkTransportAdd from './LkTransportAdd'

export default function LkTaksoparkBlock() {
  return (
    <LkCard title={'Таксопарк Истринский №1'}>
      <LkTransport active />
      <LkTransport />
      <LkTransportAdd />
    </LkCard>
  )
}