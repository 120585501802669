import React from 'react';
import * as PropTypes from 'prop-types';
import { colors } from '../styles/colors';
import {makeStyles} from "@material-ui/core/styles";
import { CenteredAbsolute } from '../styles/sharable'

const useStyles =  makeStyles(theme => ({
  DividerTitle: {
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  Line: {
    background: colors.gray12,
    width: "95%",
    height: "1px",
    zIndex: 10,
    ...CenteredAbsolute
  },
  Text: {
    display: "inline-block",
    padding: 20,
    color: colors.gray2,
    background: colors.gray7,
    fontSize: 18,
    zIndex: 100
  }
}));

export default function DividerTitle(props) {
  const classes = useStyles();

  return (
    <div className={classes.DividerTitle}>
      <div className={classes.Line} />
      <span className={classes.Text} >
        { props.title }
      </span>
    </div>
  )
}

DividerTitle.propTypes = {
  title: PropTypes.string
};

