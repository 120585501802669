import { fontSizes } from './font-sizes'
import { colors } from './colors'
import {media} from './media'

export const CenteredAbsolute = {
  position: "absolute",
  margin: "auto",
  left: "0",
  right: "0",
  bottom: "0",
  top: "0",
}

export const InputCommon = {
  width: '100%',
  height: 50,
  fontSize: fontSizes.default,
  background: `${colors.white} !important`,
  border: `1px solid ${colors.borderColor}`,
  borderRadius: 5,
  boxShadow: `1px 2px 1px 0 ${colors.borderShadow} inset`,
  '&:hover': {
    background: `${colors.white} !important`
  },
  '&::placeholder': {
    color: colors.gray8
  },
}

export const AuthPageStyles = {
  Root: {
    minHeight: "100vh",
    width: "100%",
  },
  Body: {
    zIndex: 100,
    position: "absolute",
    width: "100%",
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  Shadow: {
    background: "rgba(0, 0, 0, 0.4)",
    position: "fixed",
    right: "0",
    bottom: "0",
    minWidth: "100%",
    minHeight: "100%",
    zIndex: 80
  },
  Video: {
    position: "fixed",
    right: "0",
    bottom: "0",
    minWidth: "100%",
    minHeight: "100%",
    zIndex: 10
  }
}

export const AuthFormStyles = {
  Root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "50px 0",
    paddingBottom: "0"
  },
  Body: {
    backgroundColor: colors.bgTransparent,
    width: "370px",
    height: "auto",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
  },
}

export const AuthFormStylesTextFields = {
  root: {
    width: '100%',
    '&:last-child': {
      marginTop: '100px !important'
    }
  },
}

export const SlideArrow = {
  Root: {
    display: "flex",
    border: "none",
    background: "none",
    position: "absolute",
    top: "0",
    bottom: "0",
    margin: "auto",
    width: "50px",
    height: "75px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  Left: {
    left: "5px"
  },
  Right: {
    right: "5px"
  }
}

export const StepsStyles = {
  Steps: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
    margin: "0 auto"
  },
  Step: {
    width: "50px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: colors.gray9
  },
  StepActive: {
    color: colors.red,
  },
  FirstStep: {
    "&::after": {
      content: "'1'",
      position: "absolute",
      fontSize: "36px",
      left: "19px",
      bottom: "6px"
    },
  },
  SecondStep: {
    "&::after": {
      content: "'2'",
      position: "absolute",
      fontSize: "36px",
      left: "16px",
      bottom: "7px"
    },
  },
  ThirdStep: {
    "&::after": {
      content: "'3'",
      position: "absolute",
      fontSize: "36px",
      left: "16px",
      bottom: "7px"
    },
  },
  Line: {
    flexGrow: 2,
    height: "3px",
    width: "100px",
    backgroundColor: colors.gray9,
    color: colors.gray9,
    position: "relative"
  },
  LineActive: { backgroundColor: colors.red, color: colors.red },
  Arrow: {
    position: "absolute",
    right: "-6px",
    bottom: "-17px",
    width: "30px"
  },
  ArrowIcon: { strokeWidth: "2px" },
  StepIcon: { position: "absolute", bottom: "70px" },
  StepDescr: {
    position: "absolute",
    fontSize: fontSizes.default,
    top: "65px",
    width: "200px",
    textAlign: "center",
    color: colors.black,
    [media[1024]]: {
      fontSize: "14px", width: "100px"
    }
  }
}

export function tableRowStyle(rowData, index) {
  if (!(index%2)) {
    return {backgroundColor: "#FFF"}
  }
}

export const fieldSetStyles = {
  Fieldset: {
    marginTop: "35px",
    border: `1px solid ${colors.gray12}`,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-start",
    height: "120px"
  },
  FieldsetLabel: {
    width: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "0 20px",
    color: colors.gray12,
  },
  FieldsetBody: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100%'
  },
}

export const checkboxInFieldSet = {
  Root: {
    marginTop: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    width: "205px",
    float: "left",
  },
  Text: {
    color: colors.black,
    fontSize: fontSizes.small,
    marginBottom: 0
  },
  InputWrapper: { color: colors.red }
}
