import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Lk from '../../lk/Lk'
import LkHeader from '../../lk/LkHeader'
import LkHeaderAppend from '../../lk/LkHeaderAppend'
import LkBody from '../../lk/LkBody'
import LkEditButton from './LkEditButton'
import LkUserInfoForm from '../../lk/LkUserInfoForm'
import Button from '../../Button'
import DividerTitle from '../../DividerTitle'
import LkPersonalTabScans from './LkPersonalTabScans'
import LkHeaderWorkStatus from '../../lk/LkHeaderWorkStatus'
import AvatarBlock from '../../AvatarBlock'

const styles = {
  InputCountryWrapper: { width: "100%" },
  InfoForm: {
    display: "flex",
    paddingTop: "25px",
    justifyContent: "space-between"
  },
  LeftBlock: { width: "170px" },
  Body: { padding: "25px" },
  EditTop: { display: "flex", justifyContent: "flex-end" },
  SendRequest: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "40px"
  },
  SendRequestButton: {
    padding: "15px 20px"
  }
}

const useStyles = makeStyles(styles)

export default function LkPersonalTab(props) {
  const classes = useStyles(props)

  return (
    <Lk>
      <LkHeader append={
        <LkHeaderAppend
          title='Личный кабинет'
          backLink={'/some'}
          rightSlot={<LkHeaderWorkStatus type={'start'} value={'Начать работать'} />}
        />
      } />

      <LkBody activeTab={'lk'}>
        <div className={classes.Body}>
          <div className={classes.EditTop}>
            <LkEditButton active />
          </div>

          <div className={classes.InfoForm}>
            <div className={classes.InfoForm}>
              <AvatarBlock className={classes.LeftBlock} />

              <LkUserInfoForm />
            </div>
          </div>
        </div>

        <DividerTitle title='Сканы документов' />

        <LkPersonalTabScans />

        <div className={classes.SendRequest}>
          <Button
            color={'red'}
            className={classes.SendRequestButton}
            text={'Отправить запрос на получение ID'}
          />
        </div>
      </LkBody>
    </Lk>
  )
}