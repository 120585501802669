import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Footer from '../../footer/Footer'
import FooterLogos from '../../footer/FooterLogos'

const useStyles = makeStyles(theme => ({
  Root: {
    background: 'transparent'
  }
}));

export default function LoadPageFooter(props) {
  const classes = useStyles(props)

  return (
    <Footer
      classes={classes.Root}
      prependSlot={<FooterLogos />}
      transparent
      withoutLine={props.withoutLine}
    />
  )
}
