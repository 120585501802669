import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {colors} from '../../../styles/colors'
import LkTransportStatusIcon from './LkTransportStatusIcon'
import {ReactComponent as CarIcon} from '../../../assets/icons/car.svg'
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg'
import clsx from 'clsx'

const styles = {
  Root: {
    width: "272px",
    background: colors.gray7,
    padding: "8px",
    display: "flex",
    justifyContent: "space-between",
    minHeight: "126px",
    margin: "7px",
  },
  CarIcon: {
    width: "76px",
    height: "53px",
  },
  CarIconActive: {
    '& path': {
      fill: colors.green2
    }
  },
  Center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  Close: { width: "24px", height: "24px", cursor: "pointer" },
  Name: {
    paddingTop: "13px",
    fontSize: "14px"
  }
}
const useStyles = makeStyles(styles)

export default function LkTransport(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.Root}>
      <div className={classes.Left}>
        <LkTransportStatusIcon active={props.active} />
      </div>

      <div className={classes.Center}>
        <CarIcon className={
          clsx(
            classes.CarIcon,
            {[classes.CarIconActive]: props.active}
          )}
        />

        <div className={classes.Name}>
          Honda CRV T123BB 095
        </div>
      </div>

      <div>
        <CloseIcon className={classes.Close} />
      </div>
    </div>
  )
}