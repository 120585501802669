import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {colors} from '../../styles/colors'
import {fontFamily} from '../../styles/font-family'
import clsx from 'clsx'
import Counter from '../Counter'

const styles = {
  Root: {
    position: "absolute",
    height: "60px",
    width: "952px",
    top: "-60px",
    left: "5px",
  },
  Tab: {
    fontSize: "16px",
    position: "absolute",
    boxShadow: "3px -1px 2px 0 #3535350d",
    border: "none",
    bottom: "0",
    cursor: "pointer",
    borderRadius: "10px 10px 0 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  TabFirst: {
    zIndex: 20,
    left: "5px",
    height: "45px",
    width: "315px",
    background: colors.orange
  },
  TabSecond: {
    zIndex: 19,
    background: colors.orange,
    left: "315px",
    height: "50px",
    width: "300px"
  },
  TabThird: {
    zIndex: 18,
    background: colors.yellowLight,
    left: "595px",
    height: "40px",
    width: "250px"
  },
  TabFourth: {
    zIndex: 17,
    left: "825px",
    height: "35px",
    width: "300px"
  },
  TabActive: {
    zIndex: 25,
    height: "60px",
    background: colors.gray7,
    color: colors.red2,
    fontFamily: fontFamily.bold
  }
}
const useStyles = makeStyles(theme => styles)


function DriverTabs(classes, props) {
  return (
    <div className={classes.Root}>
      <button
        className={clsx(classes.Tab, classes.TabFirst, {[classes.TabActive]: props.activeTab === 'lk'})}
      >
        Личные данные и документы
      </button>

      <button
        className={clsx(classes.Tab, classes.TabSecond, {[classes.TabActive]: props.activeTab === 'ts'})}
      >
        Таксопарки и ТС
      </button>

      <button
        className={clsx(classes.Tab, classes.TabThird, {[classes.TabActive]: props.activeTab === 'travel'})}
      >
        Путевой лист
      </button>

      <button
        className={clsx(classes.Tab, classes.TabFourth, {[classes.TabActive]: props.activeTab === 'message'})}
      >
        <Counter value="0" />
        Сообщения
      </button>
    </div>
  )
}

function OperatorTabs(classes, props) {
  return (
    <div className={classes.Root}>
      <button
        className={clsx(classes.Tab, classes.TabFirst, {[classes.TabActive]: props.activeTab === 'requests'})}
      >
        Заявления
      </button>

      <button
        className={clsx(classes.Tab, classes.TabSecond, {[classes.TabActive]: props.activeTab === 'organizations'})}
      >
        Организации
      </button>

      <button
        className={clsx(classes.Tab, classes.TabThird, {[classes.TabActive]: props.activeTab === 'employees'})}
      >
        Сотрудники
      </button>

      <button
        className={clsx(classes.Tab, classes.TabFourth, {[classes.TabActive]: props.activeTab === 'uploads'})}
      >
        Выгрузки
      </button>
    </div>
  )
}

function OperatorTaksoparkaTabs(classes, props) {
  return (
    <div className={classes.Root}>
      <button
        className={clsx(classes.Tab, classes.TabFirst, {[classes.TabActive]: props.activeTab === 'travel-list'})}
      >
        Путевые листы
      </button>

      <button
        className={clsx(classes.Tab, classes.TabSecond, {[classes.TabActive]: props.activeTab === 'employees'})}
      >
        Сотрудники
      </button>

      <button
        className={clsx(classes.Tab, classes.TabThird, {[classes.TabActive]: props.activeTab === 'ts'})}
      >
        Транспортные средства
      </button>

      <button
        className={clsx(classes.Tab, classes.TabFourth, {[classes.TabActive]: props.activeTab === 'organizations'})}
      >
        Доверенные организации
      </button>
    </div>
  )
}

function SpecialistAndMedworker(classes, props) {
  return (
    <div className={classes.Root}>
      <button
        className={clsx(classes.Tab, classes.TabFirst, {[classes.TabActive]: props.activeTab === 'active-requests'})}
      >
        Активные запросы
      </button>

      <button
        className={clsx(classes.Tab, classes.TabSecond, {[classes.TabActive]: props.activeTab === 'history'})}
      >
        История запросов
      </button>
    </div>
  )
}

function Analitics(classes, props) {
  return (
    <div className={classes.Root}>
      <button
        className={clsx(classes.Tab, classes.TabFirst, {[classes.TabActive]: props.activeTab === 'reports'})}
      >
        Отсчеты системы
      </button>

      <button
        className={clsx(classes.Tab, classes.TabSecond, {[classes.TabActive]: props.activeTab === 'reports2'})}
      >
        Отсчеты системы 2
      </button>
    </div>
  )
}

export default function LkBodyTabs(props) {
  const classes = useStyles(props)

  if (props.type === 'operator') {
    return OperatorTabs(classes, props)
  }

  if (props.type === 'operator-taksoparka') {
    return OperatorTaksoparkaTabs(classes, props)
  }

  if (props.type === 'other') {
    return SpecialistAndMedworker(classes, props)
  }

  if (props.type === 'analitics') {
    return Analitics(classes, props)
  }

  return DriverTabs(classes, props)
}