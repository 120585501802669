import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {fontFamily} from '../../../styles/font-family'
import {colors} from '../../../styles/colors'
import LkCard from '../../lk/LkCard'
import clsx from 'clsx'
import Button from '../../Button'

const styles = {
  Body: {
    width: "272px",
    background: colors.gray7,
    padding: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "126px",
    flexDirection: "column",
    position: "relative",
  },
  BodyStateProgress: { background: colors.orange },
  StatusImage: { position: "absolute", right: "-10px", top: "-10px" },
  Name: {
    paddingTop: "13px",
    fontSize: "14px"
  },
  Responsible: {
    fontSize: "12px",
    color: colors.blue,
    lineHeight: "20px",
    padding: "10px"
  },
  Status: {
    textTransform: "uppercase",
    fontFamily: fontFamily.black,
    fontSize: "16px",
    position: "absolute",
    top: "3px",
    textAlign: "center",
    color: colors.white
  }
}

const buttonStyles = {
  Root: {
    marginTop: "25px",
    width: "100%",
    padding: "15px 20px"
  },
}

const cardStyles = {
  Root: {
    paddingBottom: "75px",
  }
}

const useStyles = makeStyles(theme => styles)

function BottomSlot({ organization, classes }) {
  if (!organization.responsible) return null

  return (
    <div className={classes.Responsible}>
      Ответственный: { organization.responsible }
    </div>
  )
}

function Status({ status, classes }) {
  let body = null

  if (status === 'progress') {
    body = (
      <div className={classes.Status}>
        в обработке
      </div>
    )
  } else if (status === 'completed') {
    body = (
      <img
        className={classes.StatusImage}
        src={require('../../../assets/images/stamp.png')}
        alt={'completed'}
      />
    )
  }

  return body
}

export default function LkOrganization(props) {
  const classes = useStyles(props)
  const cardClasses = makeStyles(cardStyles)()
  const buttonClasses = makeStyles(buttonStyles)()
  const bodyClasses = clsx(
    classes.Body,
    {[classes.BodyStateProgress]: props.organization.status === 'progress'}
  )

  if (!props.organization) return null

  return (
    <LkCard
      title={props.organization.title}
      classes={cardClasses}
      bottomSlot={
        <BottomSlot
          organization={props.organization}
          classes={classes}
        />
      }
    >
      <div className={bodyClasses}>
        <Status classes={classes} status={props.organization.status} />

        <img className={classes.Icon} src={props.organization.icon} alt="organization" />

        <div className={classes.Name}>
          {props.organization.name}
        </div>
      </div>

      <Button
        disabled={props.organization.status !== 'empty'}
        color={'red'}
        classes={buttonClasses}
        text={'Отправить заявку'}
      />
    </LkCard>
  )
}