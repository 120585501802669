import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Modal from '../Modal'
import Button from '../Button'
import {colors} from '../../styles/colors'
import {fontSizes} from '../../styles/font-sizes'
import Select from '../Select'
import CheckboxInput from '../CheckboxInput'

const styles = {
  CompanyWrapper: {
    position: "relative",
    height: "50px",
    width: 478
  },
  SearchIcon: { position: "absolute", left: "23px", top: "15px" }
}

const modalStyles = {
  HeadingContent: { position: "absolute" },
  Content: { marginTop: "50px", flexDirection: "column !important" },
  Body: {
    width: "873px",
    height: "702px",
    backgroundColor: colors.gray7,
  }
}

const inputStyles = {
  Root: {
    marginTop: "180px",
    display: "flex",
    justifyContent: "center",
    width: "auto",
  },
  Text: {
    color: colors.black,
    fontSize: fontSizes.small,
    marginTop: "4px"
  },
  InputWrapper: { color: colors.red }
}

const selectStyles = {
  Select: {
    paddingLeft: "50px",
    boxShadow: 'none'
  },
}

export default function MedicalOrganizationModal() {
  const classes = makeStyles(styles)()
  const modalClasses = makeStyles(modalStyles)()
  const inputClasses = makeStyles(inputStyles)()
  const selectClasses = makeStyles(selectStyles)()

  return (
    <Modal
      classes={modalClasses}
      headerTextSlot={
        <span> Введите наименование организации для прохождения медицинского осмотра </span>
      }
      contentSlot={
        <React.Fragment>
          <div className={classes.CompanyWrapper}>
            <Select
              label={'Наименование организации'}
              input-id="park_name_modal_select"
              classes={selectClasses}
              options={[
                {id: 1, name: 'Открытая клиника'},
                {id: 2, name: 'Клиника на открытом шоссе'},
              ]}
              fullWidth
              required
            />
          </div>

          <CheckboxInput classes={inputClasses}>
            Прохожу медицинский осмотр в таксопарке
          </CheckboxInput>
        </React.Fragment>
      }
      buttonsSlot={
        <Button
          text="Выбрать"
          color="red"
          size="big"
        />
      }
    />
  )
}