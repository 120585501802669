import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Button from '../Button'
import Modal from '../Modal'
import {colors} from '../../styles/colors'
import {media} from '../../styles/media'
import TextField from '../TextField'

const modalStyles = {
  Body: {
    width: "555px",
    height: "546px",
    backgroundColor: colors.red,
  },
  Header: {
    color: colors.white,
    width: 440,
    [media[768]]: { width: "auto" }
  },
}
const buttonStyles = {
  Root: {
    marginBottom: 20
  }
}
export default function RestorePasswordModal() {
  const modalClasses = makeStyles(modalStyles)()
  const buttonClasses = makeStyles(buttonStyles)()

  return (
    <Modal
      classes={modalClasses}
      headerTextSlot={
        <span>Введите телефон или e&#8209;mail для получения кода</span>
      }
      contentSlot={
        <TextField
          type="text"
          input-id="restore-pass-input"
          width="392px"
          centered
          required
        />
      }
      buttonsSlot={
        <React.Fragment>
          <Button
            text="Получить"
            color="orange"
            size="big"
            classes={buttonClasses}
          />

          <Button
            text="Отмена"
            color="gray"
            size="big"
            class="restore-password-modal__cancel-button"
          />
        </React.Fragment>
      }
    />
  )
}