import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Modal from '../Modal'
import {ReactComponent as CarIcon} from '../../assets/icons/car.svg'
import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow-with-line.svg'
import {colors} from '../../styles/colors'
import Button from '../Button'

const styles = {
  CarIcon: { width: "100px", height: "53px", color: colors.white }
}
const modalStyles = {
  Body: {
    width: "488px",
    height: "432px",
    backgroundColor: colors.red,
    paddingRight: "0",
    paddingLeft: "0",
  },
  Container: { padding: "0" },
  Content: {
    width: "100%",
    display: "flex",
    alignItems: "center"
  },
  Header: { color: colors.white },
  Close: { marginRight: "33px" }
}
export default function UploadDocModal() {
  const classes = makeStyles(styles)()
  const modalClasses = makeStyles(modalStyles)()

  return (
    <Modal
      classes={modalClasses}
      headerTextSlot={
        <span> Спасибо за регистрацию </span>
      }
      contentSlot={
        <React.Fragment>
          <ArrowIcon />

          <CarIcon className={classes.CarIcon} />
        </React.Fragment>
      }
      buttonsSlot={
        <Button
          text="Ок"
          size="small"
          color="white"
        />
      }
    />
  )
}