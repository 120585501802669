import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {colors} from '../../styles/colors'
import {fontFamily} from '../../styles/font-family'
import {ReactComponent as BasketIcon} from '../../assets/icons/basket.svg'

const styles = {
  Root: {
    flexDirection: "column",
    display: "inline-flex",
    justifyContent: "flex-start",
    padding: "44px 44px 65px 44px",
    borderRadius: "10px",
    background: colors.white,
    position: "relative",
    alignItems: "center",
    marginLeft: "20px",
    boxShadow: `0 2px 4px 0 ${colors.gray5}`,
    "&:first-child": {
      marginLeft: "0"
    },
  },
  Title: {
    size: "16px",
    fontFamily: fontFamily.bold,
    paddingBottom: "40px"
  },
  Delete: {
    position: "absolute",
    width: "36px",
    height: "36px",
    background: "none",
    border: "none",
    right: "20px",
    cursor: "pointer",
    bottom: "20px"
  },
  Bottom: {
    bottom: "8px",
    width: "75%",
    left: "46px",
    textAlign: "center",
    position: "absolute"
  }
}
const useStyles = makeStyles(theme => styles)

export default function LkCard(props) {
  const classes = useStyles(props)
  const {
    showDelete = true
  } = props

  return (
    <div className={classes.Root}>

      <div className={classes.Title}>
        {props.title}
      </div>

      <div className={classes.Body}>
        {props.children}
      </div>

      {
        showDelete &&
        <button className={classes.Delete}>
          <BasketIcon />
        </button>
      }

      <div className={classes.Bottom}>
        {props.bottomSlot}
      </div>
    </div>
  )
}