import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {colors} from '../../../styles/colors'
import {ReactComponent as PlusIcon} from '../../../assets/icons/plus.svg'
import Card from '../../Card'

const styles = {
  Text: {
    color: colors.red2, fontSize: "14px", textAlign: "center"
  },
  Button: {
    padding: "20px",
    border: "none",
    background: "none",
    cursor: "pointer",
    width: "120px",
    height: "145px"
  }
}

const cardStyles = {
  Root: {
    justifyContent: "center",
    alignItems: "center",
  },
}

const useStyles = makeStyles(theme => styles)
const useCardStyles = makeStyles(theme => cardStyles)

export default function LkPersonalTabScan(props) {
  const classes = useStyles(props)
  const cardClasses = useCardStyles(props)

  return (
    <Card classes={cardClasses}>
      <button className={classes.Button}>
        <PlusIcon />
        <span className={classes.Text}>
          Загрузить документ
        </span>
      </button>
    </Card>
  )
}