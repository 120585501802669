import React from 'react'
import * as PropTypes from 'prop-types'
import { colors } from '../styles/colors'
import {makeStyles} from "@material-ui/core/styles";
import {InputCommon} from '../styles/sharable'
import {getClasses} from '../helpers/mergeStylesHelper'
import { TextField as MUITextField} from '@material-ui/core'
import clsx from 'clsx'

const styles = {
  root: {},
  LabelIcon: {
    paddingRight: 5,
    paddingLeft: 5
  },
  Label: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
}

const inputStyles = {
  Root: {
    ...InputCommon,
  },
  disabled: {
    border: `1px solid ${colors.gray8}`,
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    borderRadius: 5,
    '& input': {
      border: 'none'
    }
  },
  input: ({ centered }) => ({
    textAlign: centered ? 'center' : 'left',
  })
}

const inputLabelStyles = {
  InputLabel: {
    display: 'flex',
    transform: 'translate(5px, 16px) scale(1) !important'
  },
  shrink: {
    transform: 'translate(5px, 7px) scale(0.75) !important'
  },
  dateLabel: {
    transform: 'translate(5px, 7px) scale(0.75) !important'
  },
  focused: {
    color: 'rgba(0, 0, 0, 0.54) !important'
  }
}

const useInputStyles = makeStyles(inputStyles)
const useStyles = makeStyles(styles)
const useLabelStyles = makeStyles(inputLabelStyles)

export default function TextField(props) {
  const classes = getClasses(props.classes, useStyles(props), styles);
  const inputClasses = getClasses(props.inputClasses, useInputStyles(props), inputStyles)
  const labelClasses = getClasses(props.labelClasses, useLabelStyles(props), useLabelStyles())

  return (
    <MUITextField
      className={clsx(classes.root, props.className || '')}
      fullWidth={props.fullWidth}
      label={
        <div className={classes.Label}>
          <span className={classes.LabelIcon}>{props.iconComponent}</span>
          <span>{props.label}</span>
        </div>
      }
      InputProps={{
        ...props.inputProps,
        disableUnderline: true,
        fullWidth: props.inputFullWidth,
        classes: { ...inputClasses, root: inputClasses.Root },
      }}
      InputLabelProps={{
        ...props.labelProps,
        className: clsx(labelClasses.InputLabel, {[labelClasses.dateLabel]: props.type === 'date'}),
        classes: labelClasses,
      }}
      placeholder={props.placeholder}
      type={props.type}
      required={props.required}
      onChange={props.onChange}
      value={props.value}
      variant={'filled'}
      disabled={props.disabled}
      style={{width: props.width || null}}
      id={props.inputId}
    />
  )
}

TextField.propTypes = {
  inputId: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  centered: PropTypes.bool,
  value: PropTypes.string,
  inputProps: PropTypes.object,
  labelProps: PropTypes.object
}

