import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {colors} from '../../../styles/colors'
import {ReactComponent as PlusIcon} from '../../../assets/icons/plus.svg'

const styles = {
  Root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "272px",
    padding: "8px",
    margin: "7px",
    cursor: "pointer",
    border: "none",
    background: "none",
  },
  Icon: {
    height: "35px",
    width: "35px"
  },
  Text: {
    textAlign: "center",
    fontSize: "12px",
    padding: "15px 30px",
    color: colors.red
  }
}
const useStyles = makeStyles(theme => styles)

export default function LkTransportAdd(props) {
  const classes = useStyles(props)

  return (
    <button className={classes.Root}>
      <PlusIcon className={classes.Icon} />

      <span className={classes.Text}>
        Добавить транспортное средство
      </span>
    </button>
  )
}