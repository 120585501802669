import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import {colors} from '../../../styles/colors'

const styles = {
  Root: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    border: `2px solid ${colors.gray14}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: colors.white,
  },
  RootActive: {
    border: `3px solid ${colors.green2}`
  },
  Fill: {
    background: colors.green2,
    height: "14px",
    width: "14px",
    borderRadius: "50%"
  }
}

const useStyles = makeStyles(theme => styles)

export default function LkTransportStatusIcon(props) {
  const classes = useStyles(props)

  return (
    <div className={
      clsx(
        classes.Root,
        {[classes.RootActive]: props.active}
      )
    }>
      {props.active && <div className={classes.Fill} />}
    </div>
  )
}