import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {colors} from '../../styles/colors'
import Select from '../Select'
import Button from '../Button'
import Modal from '../Modal'
import {fontSizes} from '../../styles/font-sizes'
import CheckboxInput from '../CheckboxInput'

const styles = {
  CompanyWrapper: {
    position: "relative",
    height: "50px",
    width: 478
  },
}
const selectStyles = {
  Select: {
    paddingLeft: "50px"
  },
  root: {
    width: '100%',
  },
}
const checkboxStyles = {
  Root: {
    marginTop: "180px",
    display: "flex",
    justifyContent: "center",
  },
  Text: {
    color: colors.black,
    fontSize: fontSizes.small,
    marginTop: "4px"
  },
  InputWrapper: { color: colors.red }
}
const modalStyles = {
  Body: {
    width: "873px",
    height: "702px",
    backgroundColor: colors.gray7,
  },
  HeadingContent: { position: "absolute" },
  Content: { marginTop: "50px", flexDirection: "column !important" }
}

export default function ParkNameModal() {
  const classes = makeStyles(styles)()
  const selectClasses = makeStyles(selectStyles)()
  const modalClasses = makeStyles(modalStyles)()
  const checkboxClasses = makeStyles(checkboxStyles)()

  return (
    <Modal
      classes={modalClasses}
      headerTextSlot={
        <span> Введите наименование организации для прохождения контроля технического состояния транспортных средств </span>
      }
      contentSlot={
        <React.Fragment>
          <div className={classes.CompanyWrapper}>
            <Select
              label={'Наименование организации'}
              input-id="park_name_modal_select"
              classes={selectClasses}
              options={[
                {id: 1, name: 'Прайм моторс'},
                {id: 2, name: 'ИОН Моторс'},
              ]}
              fullWidth
              required
            />
          </div>

          <CheckboxInput classes={checkboxClasses}>
            Прохожу ТО в таксопарке
          </CheckboxInput>
        </React.Fragment>
      }
      buttonsSlot={
        <Button
          text="Выбрать"
          color="red"
          size="big"
        />
      }
    />
  )
}