import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {getClasses} from '../../../helpers/mergeStylesHelper'
import Lk from '../../lk/Lk'
import LkHeader from '../../lk/LkHeader'
import LkHeaderAppend from '../../lk/LkHeaderAppend'
import LkBody from '../../lk/LkBody'


const styles = {
  Root: {}
}
const useStyles = makeStyles(styles)

export default function LkSpecialistActiveRequests(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)

  return (
    <Lk classes={classes}>
      <LkHeader title={'специалист техник'} append={
        <LkHeaderAppend
          title='Личный кабинет'
          backLink={'/some'}
        />
      } />

      <LkBody activeTab={'active-requests'} type={'other'}>

      </LkBody>
    </Lk>
  )
}