import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Modal from '../Modal'
import TextField from '../TextField'
import Button from '../Button'
import {colors} from '../../styles/colors'
import {ReactComponent as PhoneInMessageIcon} from '../../assets/icons/phone-in-message.svg'

const styles = {
  PhoneIcon: {
    margin: "40px 0"
  }
}
const modalStyles = {
  Body: {
    backgroundColor: colors.orange,
    width: "647px",
    height: "702px",
  },
  Header: {
    color: colors.gray2,
  },
  Content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}

export default function FeedbackModal() {
  const classes = makeStyles(styles)()
  const modalClasses = makeStyles(modalStyles)()

  return (
    <Modal
      classes={modalClasses}
      headerTextSlot={
        <span> Заказать звонок обратной связи </span>
      }
      contentSlot={
        <React.Fragment>
          <TextField
            type="tel"
            input-id="input-tel"
            width="325px"
            placeholder="+7 (999) 666 33 22"
            centered
            required
          />

          <PhoneInMessageIcon className={classes.PhoneIcon} />
        </ React.Fragment>
      }
      buttonsSlot={
        <Button
          text="Жду звонка"
          color="red"
          size="big"
        />
      }
    />
  )
}