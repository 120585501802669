import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import TextField from '../TextField'
import Select from '../Select'
import {getClasses} from '../../helpers/mergeStylesHelper'

const styles = {
  Root: {
    marginTop: -10,
    width: "calc(100% - 194px)",
    flexWrap: "wrap",
    alignContent: "flex-start",
    display: "flex",
    justifyContent: "space-between",
  },
  InputCountryWrapper: {
    width: "100%"
  },
}

const textFieldStyles = {
  input: {
    padding: "10px",
  },
  InputName: { width: "22.55%"},
  InputSecondName: { width: "34.58%"},
  InputLastName: { width: "42.85%", paddingRight: "0" },
  InputEmail: { width: "36.58%" },
  InputPhone: { width: "20.55%" },
  InputInn: { width: "42.85%", paddingRight: "0" },
  InputCountry: { width: "35%" },
  InputPassSerial: { width: "9.77%" },
  InputPassNumber: { width: "28.72%" },
  InputPassDate: { width: "61.50%", paddingRight: "0" },
  InputAddress: { width: "100%", paddingRight: "0" }
}

const useStyles = makeStyles(styles)

export default function LkUserInfoForm(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)

  const getTextFieldClasses = (name) => {
    if (!name) return ''

    return makeStyles({
      root: {
        ...textFieldStyles.input,
        ...(textFieldStyles[name] ? textFieldStyles[name] : {})
      }
    })()
  }

  return (
    <div className={classes.Root}>
      <TextField
        classes={getTextFieldClasses('InputName')}
        label="Имя"
      />

      <TextField
        classes={getTextFieldClasses('InputSecondName')}
        label="Фамилия"
      />

      <TextField
        classes={getTextFieldClasses('InputLastName')}
        label="Отчество"
      />

      <TextField
        classes={getTextFieldClasses('InputEmail')}
        label="Электронная почта"
      />

      <TextField
        classes={getTextFieldClasses('InputPhone')}
        label="Телефон"
      />

      <TextField
        classes={getTextFieldClasses('InputInn')}
        label="ИНН"
      />

      <div className={classes.InputCountryWrapper}>
        <Select
          label="Страна"
          classes={getTextFieldClasses('InputCountry')}
          options={[ { id: 1, name: 'Россия' }, { id: 2, name: 'США' } ]}
        />
      </div>

      <TextField
        classes={getTextFieldClasses('InputPassSerial')}
        label="Серия"
        required
      />

      <TextField
        classes={getTextFieldClasses('InputPassNumber')}
        label="Номер паспорта"
        required
      />

      <TextField
        classes={getTextFieldClasses('InputPassDate')}
        label="Кем и когда выдан"
        required
      />

      <TextField
        classes={getTextFieldClasses('InputAddress')}
        label="Адрес"
        disabled
      />
    </div>
  )
}