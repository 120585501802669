import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Modal from '../Modal'
import TextField from '../TextField'
import Button from '../Button'
import {colors} from '../../styles/colors'
import {fontSizes} from '../../styles/font-sizes'
import {getClasses} from "../../helpers/mergeStylesHelper";
import CheckboxInput from "../CheckboxInput";
import {checkboxInFieldSet, fieldSetStyles} from '../../styles/sharable'
import {getInputClass as getInputClassHelper} from '../../helpers/getInputClass'


const styles = {
  ...fieldSetStyles,
  Wrapper: {
    display: "flex",
    flexWrap: 'wrap',
    width: "100%"
  },
  OkButton: {
    margin: "30px 10px 10px",
    width: "calc(10% - 20px)"
  },
  CloseButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "150px"
  },
  TopCheckBox: {
    padding: "10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: 'center',
    width: "49%",
    margin: "10px 0"
  },
  CheckboxTech: {
    padding: "10px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: 'center',
    width: "40%",
    margin: "10px 0"
  },
  CheckboxText: {
    color: colors.black,
    fontSize: fontSizes.small,
    marginBottom: 0
  },
  CheckboxWrapper: {
    color: colors.red
  },
  BottomWrapper: {
    width: "100%",
  },
}

export const inputStyles = {
  Input: {
    margin: "10px",
  },
  InputFifty: {width: '47%'},
  InputINN1:{width: "calc(30% - 20px)"},
  InputINN2: {width: "calc(22% - 20px)"},
  InputINN3: {width: "calc(22% - 20px)"},
  InputPhone: {width: "calc(26% - 20px)"},
  InputPerson: {width: "calc(38% - 20px)"},
  InputSite: {width: "calc(35% - 20px)"},
  InputEmail: {width: "calc(27% - 20px)"},
  InputLicense: {width: "calc(45% - 20px)", marginTop: "30px"},
  InputEnterLicense: {width: "calc(45% - 30px)", marginTop: "30px"},
}

const modalStyles = {
  Body: {
    padding: "25px 25px 0",
    width: "873px",
    height: "auto",
    backgroundColor: colors.gray7,
  },
  Content: {
    display: "flex",
    flexWrap: 'wrap',
    flexDirection: "column",
    width: "100%"
  },
  Header: {color: colors.gray2},
  Buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "40px",
    paddingBottom: "30px"
  }
}
const checkboxStyles = checkboxInFieldSet

const useStyles = makeStyles(styles)
const useModalStyles = makeStyles(modalStyles)
const useCheckboxStyles = makeStyles(checkboxStyles)

export default function TrustedOrganizationModal(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)
  const modalClasses = useModalStyles()
  const checkboxClasses = useCheckboxStyles()
  const getInputClass = name => getInputClassHelper(name, inputStyles)

  return (
    <Modal
      classes={modalClasses}
      headerTextSlot={
        <span>Доверенная организация</span>
      }
      contentSlot={
        <React.Fragment>
          <div className={classes.Wrapper}>
            <CheckboxInput
              checked
              classes={{Root: classes.TopCheckBox, Text: classes.CheckboxText, InputWrapper: classes.CheckboxWrapper}}
            >
              Организация является ИП/самозанятым
            </CheckboxInput>

            <CheckboxInput
              classes={{Root: classes.TopCheckBox, Text: classes.CheckboxText, InputWrapper: classes.CheckboxWrapper}}
            >
              Аккредитация
            </CheckboxInput>

            <TextField
              classes={getInputClass('InputFifty')}
              label="Полное наименование орг."
              value="ООО 'Техносервис'"
            />

            <TextField
              classes={getInputClass('InputFifty')}
              label="Краткое наименование организации"
              value="Техсервис"
            />

            <TextField
              classes={getInputClass('InputFifty')}
              label="Адрес местонахождения"
              value="ул. Бориса Пастернака 35, кв. 305"
            />

            <TextField
              classes={getInputClass('InputFifty')}
              label="Юридический адрес"
              value="123456 Москва, ул. Бориса Пастернака 35, кв. 305"
            />

            <TextField
              classes={getInputClass('InputINN1')}
              label="ОРГН"
              value="770112345678"
            />

            <TextField
              classes={getInputClass('InputINN2')}
              label="ИНН"
              value="770112345"
            />

            <TextField
              classes={getInputClass('InputINN3')}
              label="КПП"
              value="1234567"
            />

            <TextField
              classes={getInputClass('InputPhone')}
              label="Телефон"
              value="+7 499 1234567"
            />

            <TextField
              classes={getInputClass('InputPerson')}
              label="Генеральный директор"
              value="Иванов Виктор Владимирович"
            />

            <TextField
              classes={getInputClass('InputSite')}
              label="Веб-сайт"
              value="technos.ru"
            />

            <TextField
              classes={getInputClass('InputEmail')}
              label="E-mail"
              value="info@technos.ru"
            />
          </div>

          <div className={classes.BottomWrapper}>
            <fieldset className={classes.Fieldset}>
              <legend className={classes.FieldsetLabel}>Полномочия</legend>
              <div className={classes.FieldsetBody}>
                <CheckboxInput classes={checkboxClasses}>
                  Услуги такси
                </CheckboxInput>

                <CheckboxInput classes={checkboxClasses}>
                  Проведение медосмотров
                </CheckboxInput>

                <CheckboxInput classes={checkboxClasses}>
                  Проведение техосмотров
                </CheckboxInput>
              </div>
            </fieldset>
          </div>
        </React.Fragment>
      }

      buttonsSlot={
        <div className={modalClasses.Buttons}>
          <Button
            text="Отмена"
            color="red"
            size="big"
          />

          <Button
            text="Сохранить"
            color="blue"
            size="big"
          />
        </div>
      }
    />
  )
}

