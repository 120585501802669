import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Modal from '../Modal'
import {colors} from '../../styles/colors'
import {ReactComponent as DocumentIcon} from '../../assets/icons/document.svg'
import Button from '../Button'

const modalStyles = {
  Body: {
    width: "488px",
    height: "432px",
    backgroundColor: colors.red,
  },
  Header: { color: colors.white },
  Buttons: {
    display: "flex",
    justifyContent: "space-between",
    width: "320px"
  }
}

export default function RemoveDocModal() {
  const modalClasses = makeStyles(modalStyles)()

  return (
    <Modal
      classes={modalClasses}
      headerTextSlot={
        <span> Удалить документ? </span>
      }
      contentSlot={
        <DocumentIcon />
      }
      buttonsSlot={
        <React.Fragment>
          <Button
            text="Нет"
            color="light-red"
            size="small"
          />
          <Button
            text="Да"
            color="white"
            size="small"
          />
        </React.Fragment>
      }
    />
  )
}