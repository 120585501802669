import React from 'react'
import * as PropTypes from 'prop-types'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import {getClasses} from '../helpers/mergeStylesHelper'
import {makeStyles} from '@material-ui/core/styles'
import {colors} from '../styles/colors'

const styles = {
  root: {},
  Input: {
    background: colors.white,
    height: 50,
  }
}

const useStyles = makeStyles(styles)

export default function Select(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)

  const defaultOptions = [
    { id: 1, name: 'Опция 1' },
    { id: 2, name: 'Опция 2' },
    { id: 3, name: 'Опция 3' },
    { id: 4, name: 'Опция 4' }
  ]
  const options = props.options || defaultOptions
  console.log(options)

  return (
    <Autocomplete
      options={options}
      classes={classes}
      fullWidth={props.fullWidth}
      renderInput={params => (
        <TextField
          {...params}
          variant={'outlined'}
          label={props.label}
          fullWidth={props.fullWidth}
          InputProps={{
            ...params.InputProps,
            classes: {
              root: classes.Input
            }
          }}
        />
      )}
      getOptionLabel={option => option.name}
    />
  )
}

Select.propTypes = {
  inputId: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  centered: PropTypes.bool,
  options: PropTypes.array,
  fullWidth: PropTypes.bool,
};

