import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import LoadPageLoader from './LoadPageLoader'
import LoadPageFooter from './LoadPageFooter'
import Video from '../../../assets/videos/login.mp4'

const useStyles =  makeStyles(theme => ({
  LoadPage: {
    height: "100vh",
    width: "100%",
  },
  Body: {
    zIndex: 100,
    position: "absolute",
    width: "100%",
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center"
  },
  Shadow: {
    background: "rgba(0, 0, 0, 0.4)",
    position: "fixed",
    right: "0",
    bottom: "0",
    minWidth: "100%",
    minHeight: "100%",
    zIndex: 80
  },
  Video: {
    position: "fixed",
    right: "0",
    bottom: "0",
    minWidth: "100%",
    minHeight: "100%",
    zIndex: 10
  }
}));

export default function LoadPage(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.LoadPage}>
      <div className={classes.Body}>
        <LoadPageLoader />
        <LoadPageFooter withoutLine />
      </div>

      <div className={classes.Shadow} />

      <video autoPlay muted loop id="myVideo" className={classes.Video}>
        <source src={Video} type="video/mp4" />
      </video>
    </div>
  )
}
