import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Video from '../../../assets/videos/login.mp4'
import LoginHeader from './LoginHeader'
import LoginForm from './LoginForm'
import LoginFooter from './LoginFooter'
import {AuthPageStyles} from '../../../styles/sharable'

const useStyles = makeStyles(AuthPageStyles)

export default function LoadPageFooter(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.Root}>
      <div className={classes.Body}>
        <LoginHeader />
        <LoginForm />
        <LoginFooter />
      </div>

      <div className={classes.Shadow} />

      <video autoPlay muted loop id="myVideo" className={classes.Video}>
        <source src={Video} type="video/mp4" />
      </video>
    </div>
  )
}
