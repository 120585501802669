import React from 'react'
import * as PropTypes from 'prop-types'
import { colors } from '../styles/colors'
import { fontSizes } from '../styles/font-sizes'
import { makeStyles } from "@material-ui/core/styles";
import Label from './Label'
import { ReactComponent as UploadIcon } from '../assets/icons/upload.svg'
import {getClasses} from '../helpers/mergeStylesHelper'

const styles = {
  Root: {},
  FileLabel: {
    height: 50,
    width: "100%",
    padding: "0 20px",
    fontSize: fontSizes.default,
    background: colors.white,
    border: `1px solid ${colors.borderColor}`,
    borderRadius: 5,
    boxShadow: `1px 2px 1px 0 ${colors.borderShadow} inset`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '&:disabled': {
      border: `1px solid ${colors.gray8}`,
      backgroundColor: 'transparent',
      boxShadow: 'none'
    }
  },
  FileName: {
    fontSize: '14px',
    color: colors.gray8
  },
  Input: {
    display: 'none'
  },
  Icon: {
    cursor: 'pointer'
  },
  Label: {
    marginBottom: 5,
    marginLeft: 9,
    fontSize: 15,
    color: colors.black
  }
}

const useStyles =  makeStyles(styles);

export default function FileInput(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)

  return (
    <div className={classes.Root}>
      <Label inputId={props.inputId} label={props.label} required={props.required} />

      <label className={classes.FileLabel}>
        <span className={classes.FileName}>Свидетельство.pdf</span>

        <input
          id={ props.inputId }
          type="file"
          className={classes.Input}
        />

        <UploadIcon className={classes.Icon} />
      </label>
    </div>
  )
}

FileInput.propTypes = {
  label: PropTypes.string,
  inputId: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool
};

