import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Lk from '../../lk/Lk'
import LkHeader from '../../lk/LkHeader'
import LkHeaderAppend from '../../lk/LkHeaderAppend'
import LkDocumentsDocs from './LkDocumentsDocs'
import LkSorting from '../../lk/LkSorting'
import TextField from '../../TextField'
import {ReactComponent as SearchIcon} from '../../../assets/icons/search.svg'
import LkHeaderWorkStatus from '../../lk/LkHeaderWorkStatus'

const styles = {
  Docs: {
    width: "100%"
  },
  Body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "1000px",
    maxWidth: "80%"
  },
  Search: { position: "relative", height: "50px" },
  SearchBar: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "40px",
    marginTop: "30px"
  },
  SearchInput: {
    width: '100%'
  },
  Lk: {
    minHeight: '100vh'
  }
}

const useStyles = makeStyles(styles)

export default function LkDocuments(props) {
  const classes = useStyles(props)
  const timer = '7:59:59'

  return (
    <Lk className={classes.Lk}>
      <LkHeader append={
        <LkHeaderAppend
          title={'Документы'}
          backLink={'/some'}
          rightSlot={<LkHeaderWorkStatus type={'work'} value={timer}/>}
        />
      } />

      <div className={classes.Body}>
        <div className={classes.SearchBar}>
          <div className={classes.Search}>
            <TextField
              inputClasses={{ input: classes.SearchInput }}
              iconComponent={<SearchIcon />}
              type="text"
              inputId="search-documents"
              label="Поиск"
            />
          </div>

          <LkSorting />
        </div>

        <LkDocumentsDocs />
      </div>
    </Lk>
  )
}