import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Modal from '../Modal'
import TextField from '../TextField'
import Button from '../Button'
import {colors} from '../../styles/colors'

const modalStyles = {
  Body: {
    width: "555px",
    height: "546px",
    backgroundColor: colors.gray7,
  },
  Header: { color: colors.gray2, width: "250px" }
}

const textFieldStyles = {
  root: {
    padding: 10,
  }
}

const useTextFieldStyles = makeStyles(textFieldStyles)
const useModalStyles = makeStyles(modalStyles)

export default function NewPasswordModal(props) {
  const modalClasses = useModalStyles()
  const textFieldClasses = useTextFieldStyles()

  return (
    <Modal
      classes={modalClasses}
      headerTextSlot={
        <span>Придумайте новый пароль</span>
      }
      contentSlot={
        <React.Fragment>
          <TextField
            type="password"
            inputId="modal-password"
            classes={textFieldClasses}
            label="Введите пароль"
            width="392px"
            centered
            required
          />

          <TextField
            type="password"
            inputId="modal-password-repeat"
            classes={textFieldClasses}
            label="Продублируйте пароль"
            width="392px"
            centered
            required
          />
        </ React.Fragment>
      }
      buttonsSlot={
        <Button
          text="Ок"
          color="orange"
          size="big"
        />
      }
    />
  )
}