import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Modal from '../Modal'
import {ReactComponent as PhotoIcon} from '../../assets/icons/photo.svg'
import {colors} from '../../styles/colors'
import Button from '../Button'
import FileInput from '../FileInput'

const inputStyles = {
  Root: {
    width: 300,
    marginTop: 50
  }
}
const modalStyles = {
  Body: {
    width: 488,
    height: 510,
    backgroundColor: colors.gray7
  },
  Header: {
    color: colors.gray2
  },
  Content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}

const useInputStyles = makeStyles(inputStyles)
const useModalStyles = makeStyles(modalStyles)

export default function UploadDocModal() {
  const inputClasses = useInputStyles()
  const modalClasses = useModalStyles()

  return (
    <Modal
      classes={modalClasses}
      headerTextSlot={
        <span> Загрузите документ </span>
      }
      contentSlot={
        <React.Fragment>
          <PhotoIcon />

          <FileInput classes={inputClasses} required />
        </React.Fragment>
      }
      buttonsSlot={
        <Button
          text="Загрузить"
          color="red"
          size="small"
        />
      }
    />
  )
}