import React from 'react'
import * as PropTypes from 'prop-types'
import { colors } from '../styles/colors'
import { fontSizes } from '../styles/font-sizes'
import { fontFamily } from '../styles/font-family'
import clsx from 'clsx';
import {makeStyles} from "@material-ui/core/styles"
import {getClasses} from '../helpers/mergeStylesHelper'
import {Button as MUIButton} from '@material-ui/core'
import {fade} from '@material-ui/core/styles'

const styles = {
  Root: {
    height: 50,
    border: 0,
    textTransform: 'none',
    borderRadius: 5,
    boxShadow: `0 4px 4px ${colors.buttonBoxShadow1}, 0 4px 8px ${colors.buttonBoxShadow2}`,
    fontSize: fontSizes.default,
    fontFamily: fontFamily.medium,
    cursor: 'pointer',
    '&:disabled': {
      background: colors.gray6,
      color: colors.white
    }
  },
  Color: ({ color }) => {
    color = color || 'red'

    const styles = {
      orange: {
        backgroundColor: colors.orange,
        color: colors.black,
      },
      red: {
        backgroundColor: colors.red,
        color: colors.white
      },
      blue: {
        backgroundColor: colors.blue,
        color: colors.white
      },
      white: {
        backgroundColor: colors.white,
        color: colors.black
      },
      gray: {
        backgroundColor: colors.gray9,
        color: colors.black
      },
      'light-gray': {
        backgroundColor: colors.gray15,
        color: colors.white
      },
      'light-red': {
        backgroundColor: colors.lightred,
        color: colors.black
      }
    };

    return {
      ...styles[color],
      '&:hover': {
        backgroundColor: fade(styles[color].backgroundColor, 0.7)
      }
    }
  },
  Size: ({ size }) => {
    const styles = {
      big: {
        width: 325
      },
      small: {
        width: 150
      },
      max: {
        width: '100%'
      }
    };

    return styles[size]
  }
}

const useStyles = makeStyles(styles);

export default function Button(props) {
  const classes = getClasses(props.classes, useStyles(props), styles);

  const buttonClasses = clsx(
    classes.Root,
    classes.Size,
    classes.Color,
    props.className
  );

  return (
    <MUIButton
      className={buttonClasses}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      { props.text }
    </MUIButton>
  )
}

Button.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string
};
