import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {colors} from '../../../styles/colors'
import {fontSizes} from '../../../styles/font-sizes'
import EsiaImg from '../../../assets/images/esia.png'

const useStyles = makeStyles({
  LoginFormEsiaEnter: {
    backgroundColor: colors.bgTransparent,
    width: "370px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "10px",
    fontSize: fontSizes.small,
    padding: "20px"
  },
  Button: {
    height: "50px",
    width: "325px",
    cursor: "pointer",
    textAlign: "left",
    paddingLeft: "40px",
    paddingTop: "2px",
    borderRadius: "5px",
    border: "0",
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: "23px",
    boxShadow: `0 4px 4px ${colors.buttonBoxShadow1}, 0 4px 8px ${colors.buttonBoxShadow2}`,
    background:
      `url('${EsiaImg}') right 45px bottom 45% / 97px 18px no-repeat ${colors.white}`
  },
  Link: {
    color: colors.white
  }
})

export default function LoginFormEsiaEnter(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.LoginFormEsiaEnter}>
      <button className={classes.Button}>
        Вход через ЕСИА
      </button>

      <a href="#reg" className={classes.Link}>
        Регистрация в ЕСИА "Госуслуги"
      </a>
    </div>
  )
}
