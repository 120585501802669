import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import LkFooter from './LkFooter'
import {colors} from '../../styles/colors'
import {getClasses} from '../../helpers/mergeStylesHelper'
import clsx from 'clsx'

const styles = {
  Root: {
    width: "100%",
    background: colors.gray6,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "100vh"
  }
}
const useStyles = makeStyles(styles)

export default function Lk(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)

  return (
    <div className={clsx(classes.Root, props.className)}>
      {props.children}

      <LkFooter />
    </div>
  )
}