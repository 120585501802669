import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {ReactComponent as ArrowLeftIcon} from '../../../assets/icons/arrow-left.svg'
import {ReactComponent as ArrowRightIcon} from '../../../assets/icons/arrow-right.svg'
import LkPersonalTabScanAdd from './LkPersonalTabScanAdd'
import LkPersonalTabScan from './LkPersonalTabScan'

const styles = {
  Root: {
    padding: "20px 0 35px 0",
    display: "flex",
    justifyContent: "space-between",
  },
  Items: { display: "flex" },
  Arrow: {
    padding: "0 20px",
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center"
  }
}
const useStyles = makeStyles(theme => styles)

export default function LkPersonalTabScans(props) {
  const classes = useStyles(props)
  const scans = [
    {
      img: require('../../../assets/images/scan1.png'),
      title: 'Медицинская страховка',
      date: '01.01.2019 - 01.01.2029',
      loaded: false
    },
    {
      img: require('../../../assets/images/scan2.png'),
      title: 'Водительское удостоверение',
      date: '01.01.2019 - 01.01.2029',
      loaded: true
    },
    {
      img: require('../../../assets/images/scan3.png'),
      title: 'Лицензия',
      date: '01.01.2019 - 01.01.2029',
      loaded: true
    },
    {
      img: require('../../../assets/images/scan4.png'),
      title: 'Миграционная карта',
      date: '01.01.2019 - 01.01.2029',
      loaded: true
    }
  ]


  return (
    <div className={classes.Root}>
      <div className={classes.Arrow}>
        <ArrowLeftIcon />
      </div>

      <div className={classes.Items}>
        { scans.map((scan, index) =>
          <LkPersonalTabScan scan={scan} key={index} />
        )}

        <LkPersonalTabScanAdd />
      </div>

      <div className={classes.Arrow}>
        <ArrowRightIcon />
      </div>
    </div>
  )
}