import clsx from 'clsx'

function getPropClass(prop, className) {
  if (!prop || !prop[className]) return ''

  return prop[className]
}

function getMergedClass(classes, propClasses, name) {
  return clsx(
    classes[name] ? classes[name] : '',
    getPropClass(propClasses, name)
  )
}

export function getClasses(propStyles, localClasses, localStyles) {
  return Object.keys(localStyles).reduce((resultClasses, name) => {
    resultClasses[name] = getMergedClass(localClasses, propStyles, name)
    return resultClasses
  }, {})
}
