import * as PropTypes from 'prop-types'
import { colors } from '../styles/colors'
import {makeStyles} from "@material-ui/core/styles";
import React from 'react'
import {getClasses} from '../helpers/mergeStylesHelper'

const styles = {
  Root: {
    marginBottom: 5,
    marginLeft: 9,
    fontSize: 15,
    color: colors.black,
    display: 'flex'
  }
}
const useStyles = makeStyles(styles)

export default function Label(props) {
  const classes = getClasses(props.classes, useStyles(props), styles);
  if (!props.label) return null

  return (
    <div className={ classes.Root }>
      {props.icon}
      <label htmlFor={ props.inputId }>{ props.label }</label>
    </div>
  )
}

Label.propTypes = {
  label: PropTypes.string,
  inputId: PropTypes.string,
  required: PropTypes.bool
}