import React from 'react'
import {makeStyles} from "@material-ui/core/styles"
import { colors } from '../styles/colors'
import {getClasses} from '../helpers/mergeStylesHelper'

const styles = {
  Root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 10,
    background: colors.white,
    width: 150,
    margin: "0 5px",
    padding: 10,
    justifyContent: 'space-between',
    boxShadow: `0 2px 4px 0 ${colors.gray5}`
  }
}

const useStyles =  makeStyles(theme => styles);

export default function Card(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)

  return (
    <div className={classes.Root}>
      { props.children }
    </div>
  )
}
