import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {colors} from '../../styles/colors'
import Button from '../Button'
import Modal from '../Modal'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {FormControl} from '@material-ui/core'
import RadioGroup from '@material-ui/core/RadioGroup'

const styles = {
  ParkWrapper: { position: "relative", height: "50px", width: "auto" },
  SearchIcon: { position: "absolute", left: "23px", top: "15px" }
}

const modalStyles = {
  Body: {
    width: "873px",
    height: "702px",
    backgroundColor: colors.gray7,
  },
  HeadingContent: { position: "absolute" },
  Content: { marginBottom: "200px" }
}

export default function SelectRequesterByType() {
  const classes = makeStyles(styles)()
  const modalClasses = makeStyles(modalStyles)()

  return (
    <Modal
      classes={modalClasses}
      headerTextSlot={
        <span>Выберете заявителя по типу <br />налогообложения</span>
      }
      contentSlot={
        <div className={classes.ParkWrapper}>
          <FormControl>
            <RadioGroup aria-label="gender" name="gender1">
              <FormControlLabel value="fiz" control={<Radio />} label="ФЛ - физическое лицо" />
              <FormControlLabel value="ur" control={<Radio />} label="ЮР - юридическое лицо" />
              <FormControlLabel value="ip" control={<Radio />} label="ИП - Индивидуальный предприниматель" />
            </RadioGroup>
          </FormControl>
        </div>
      }
      buttonsSlot={
        <Button
          text="Выбрать"
          color="red"
          size="big"
        />
      }
    />
  )
}