import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {colors} from '../../styles/colors'
import Select from '../Select'
import Button from '../Button'
import Modal from '../Modal'

const styles = {
  ParkWrapper: {
    position: "relative",
    height: "50px",
    width: 478
  },
}

const selectStyles = {
  root: {
    width: '100%',
  },
  Select: {
    paddingLeft: "50px"
  },
}
const modalStyles = {
  Body: {
    width: "873px",
    height: "702px",
    backgroundColor: colors.gray7,
  },
  HeadingContent: { position: "absolute" },
  Content: { marginBottom: "200px" }
}

export default function ParkNameModal() {
  const classes = makeStyles(styles)()
  const selectClasses = makeStyles(selectStyles)()
  const modalClasses = makeStyles(modalStyles)()

  return (
    <Modal
      classes={modalClasses}
      headerTextSlot={
        <span>Введите наименование таксопарка <br /> для поиска</span>
      }
      contentSlot={
        <div className={classes.ParkWrapper}>
          <Select
            label={'Наименование таксопарка'}
            input-id="park_name_modal_select"
            classes={selectClasses}
            options={[
              {id: 1, name: 'Таксопарк Истра'},
              {id: 2, name: 'Таксопарк Истринский №1'},
              {id: 3, name: 'Истра, таксопарк №2'},
            ]}
            fullWidth
            required
          />
        </div>
      }
      buttonsSlot={
        <Button
          text="Выбрать"
          color="red"
          size="big"
        />
      }
    />
  )
}