import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {colors} from '../../styles/colors'
import {ReactComponent as MessageIcon} from '../../assets/icons/message.svg'
import {ReactComponent as QuestionIcon} from '../../assets/icons/question.svg'
import Footer from '../footer/Footer'
import clsx from 'clsx'

const styles = {
  Root: {},
  Btn: {
    width: "66px",
    height: "66px",
    background: colors.orange,
    borderRadius: "50%",
    border: "none",
    cursor: "pointer",
    position: "absolute",
    top: "-33px",
  },
  BtnRight: {
    right: "35px"
  },
  BtnLeft: {
    left: "35px"
  },
}

const footerStyles = {
  Root: {
    marginTop: 50
  },
  BodyCenter: {
    color: colors.black
  },
  Body: {
    background: colors.gray10,
  },
}

const sideLineStyles = {
  Center: {
    "& path + path": {
      fill: colors.gray10,
      fillOpacity: 1
    }
  }
}

const useFooterStyles = makeStyles(theme => footerStyles)
const useSideLineStyles = makeStyles(sideLineStyles)
const useStyles = makeStyles(theme => styles)

export default function Lk(props) {
  const classes = useStyles(props)
  const footerClasses = useFooterStyles(props)
  const sideLineClasses = useSideLineStyles()

  return (
    <Footer
      classes={footerClasses}
      sideLineClasses={sideLineClasses}
      leftSlot={
        <button className={clsx(classes.Btn, classes.BtnLeft)}>
          <QuestionIcon />
        </button>
      }
      rightSlot={
        <button className={clsx(classes.Btn, classes.BtnRight)}>
          <MessageIcon />
        </button>
      }
    />
  )
}