import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {fontSizes} from '../../styles/font-sizes'
import {colors} from '../../styles/colors'
import {fontFamily} from '../../styles/font-family'
import clsx from 'clsx'

const styles = {
  Root: {
    width: "161px",
    height: "50px",
    border: "none",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
    cursor: "pointer",
    "& span": { color: colors.white, fontSize: fontSizes.default },
  },
  Gray: { backgroundColor: colors.gray5 },
  Yellow: { backgroundColor: colors.orange },
  Red: { backgroundColor: colors.red },
  Green: { backgroundColor: colors.green },
  Timer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0",
    "& span": {
      fontSize: fontSizes.medium,
      fontFamily: fontFamily.bold
    }
  }
}

export default function LkHeaderWorkStatus(props) {
  const classes = makeStyles(styles)()

  const blockClasses = clsx(
    classes.Root,
    {
      [classes.Yellow]: props.type === 'calculating',
    },
    {
      [classes.Gray]: props.type === 'start',
    },
    {
      [classes.Red]: props.type === 'rest',
    },
    {
      [classes.Green]: props.type === 'work',
    },
    {
      [classes.Timer]: ['rest', 'work'].includes(props.type),
    }
  )

  return (
    <div className={blockClasses}>
      <span>
        {props.value}
      </span>
    </div>
  )
}