import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {getClasses} from '../../helpers/mergeStylesHelper'
import Lk from '../lk/Lk'
import LkHeader from '../lk/LkHeader'
import LkHeaderAppend from '../lk/LkHeaderAppend'
import LkBody from '../lk/LkBody'
import EntityList from '../EntityList'
import {ReactComponent as BasketIcon} from '../../assets/icons/basket.svg'
import {colors} from '../../styles/colors'


const styles = {
  Root: {},
  CheckBoxText: {
    color: colors.black
  },
  BodyBottom: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30
  },
  LeftButtonToolbar: {
    background: colors.blue
  },
  BasketButton: {
    border: 'none',
    background: 'none',
    cursor: 'pointer'
  },
  BasketIcon: {
    '& path': {
      stroke: colors.red,
      fill: colors.white
    }
  }
}
const useStyles = makeStyles(styles)

export default function LkOperatorTaksoparkaTs(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)

  const tableData = [
    {
      mark: 'Ниссан',
      model: 'Альмера',
      grz: 'F0EIO342',
      action_date: '01/01/2022',
      delete: true
    },
    {
      mark: 'Ниссан',
      model: 'Альмера',
      grz: 'F0EIO342',
      action_date: '01/01/2022',
      delete: true
    },
    {
      mark: 'Ниссан',
      model: 'Альмера',
      grz: 'F0EIO342',
      action_date: '01/01/2022',
      delete: true
    },
    {
      mark: 'Ниссан',
      model: 'Альмера',
      grz: 'F0EIO342',
      action_date: '01/01/2022',
      delete: true
    },
    {
      mark: 'Ниссан',
      model: 'Альмера',
      grz: 'F0EIO342',
      action_date: '01/01/2022',
      delete: true
    },
  ]

  return (
    <Lk classes={classes}>
      <LkHeader title={'оператор таксопарка'} append={
        <LkHeaderAppend
          title='Личный кабинет'
          backLink={'/some'}
        />
      } />

      <LkBody activeTab={'ts'} type={'operator-taksoparka'}>
        <EntityList
          topToolbarSearchWidth={600}
          data={tableData}
          toolbarButtonText={'Добавить ТС'}
          toolbarLeftButtonClassName={classes.LeftButtonToolbar}
          columns={[
            {title: 'Марка', field: 'mark', align: 'center'},
            {title: 'Модель', field: 'model', align: 'center'},
            {title: 'ГРЗ', field: 'grz', align: 'center'},
            {title: 'Действие ДК до', field: 'action_date', align: 'center'},
            {
              title: 'Удалить запись',
              field: 'delete',
              align: 'center',
              render: () => (
                <button className={classes.BasketButton}>
                  <BasketIcon className={classes.BasketIcon} />
                </button>
              )
            },
          ]}
        />
      </LkBody>
    </Lk>
  )
}