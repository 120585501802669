import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {colors} from '../../../styles/colors'
import {ReactComponent as DocIcon} from '../../../assets/icons/document2.svg'

const styles = {
  Root: {
    marginBottom: "21px",
    marginRight: "20px",
    width: "178px",
    height: "287px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: colors.white,
    borderRadius: "10px",
    boxShadow: "0 2px 2px rgba(50, 50, 71, 0.06), 0 2px 4px rgba(50, 50, 71, 0.06)",
    padding: "20px 24px",
    fontSize: "14px",
  },
  Upper: { height: "126px", width: "130px", display: "flex" },
  Info: {
    marginTop: "15px",
    height: "120px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    textAlign: "center"
  },
  ImgIconName: { textAlign: "center", marginTop: "20px" },
  Img: { objectFit: "contain" },
  ImgIcon: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.gray7
  },
  InfoDates: { fontSize: "12px" },
  InfoNumber: { fontSize: "12px", fontWeight: 600 }
}
const useStyles = makeStyles(styles)

export default function LkDocumentsDoc(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.Root}>
      <div className={classes.Upper}>
        { props.doc.img && <img className={classes.Img} src={props.doc.img} alt="" />}

        { !props.doc.img &&
          <div className={classes.ImgIcon}>
            <DocIcon />

            <div className={classes.ImgIconName}>
              {props.doc.title}
            </div>
          </div>
        }
      </div>

      <div className={classes.Info}>
        <div className={classes.InfoNumber}>
          {props.doc.number}
        </div>

        { props.doc.img && <div> { props.doc.title} </div> }

        <div className={classes.InfoDates}>
          {props.doc.date}
        </div>
      </div>
    </div>
  )
}