import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {getClasses} from '../helpers/mergeStylesHelper'
import Button from './Button'
import {colors} from '../styles/colors'
import clsx from 'clsx'
import {fontSizes} from '../styles/font-sizes'


const styles = {
  Button: {
    marginTop: "10px",
    width: "100%",
    background: colors.gray8,
    color: colors.white,
    fontSize: fontSizes.small,
    textTransform: 'lowercase'
  },
  PhotoWrapper: {
    borderRadius: "5px",
    border: "1px solid rgba(255, 255, 255, 0.6)",
    boxSizing: "border-box",
    overflow: "hidden",
    width: "170px",
    height: "170px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Photo: { height: "100%", width: "auto" },
}

const useStyles = makeStyles(styles)

export default function AvatarBlock(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)

  return (
    <div className={clsx(classes.Root, props.className)}>
      <div className={classes.PhotoWrapper}>
        <img
          className={classes.Photo}
          src={require('../assets/images/img.png')}
          alt="profile"
        />
      </div>

      <Button
        className={classes.Button}
        text={'Выбрать фото'}
        color={'gray'}
      />
    </div>
  )
}