import React from 'react';
import WayToGetIdModal from './components/modals/WayToGetIdModal'
import LkTaksopark from "./components/pages/lk-taksopark/LkTaksopark";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import LkPersonalTab from "./components/pages/lk-personal-tab/LkPersonalTab";
import Registration from './components/pages/registration/Registration'
import LoadPage from './components/pages/load-page/LoadPage'
import LkTravelList from './components/pages/lk-travel-list/LkTravelList'
import LkDocuments from './components/pages/lk-documents/LkDocuments'
import LkMessages from './components/pages/lk-messages/LkMessages'
import LkViewDoc from './components/pages/lk-view-doc/LkViewDoc'
import NewPasswordModal from './components/modals/NewPasswordModal'
import MedicalOrganizationModal from './components/modals/MedicalOrganizationModal'
import FeedbackModal from './components/modals/FeedbackModal'
import CheckCodeModal from './components/modals/CheckCodeModal'
import CarDataModal from './components/modals/CarDataModal'
import SendMessageModal from './components/modals/SendMessageModal'
import RestorePasswordModal from './components/modals/RestorePasswordModal'
import RemoveDocModal from './components/modals/RemoveDocModal'
import ParkNameModal from './components/modals/ParkNameModal'
import OrganizationTsModal from './components/modals/OrganizationTsModal'
import TermsModal from './components/modals/TermsModal'
import StepsModal from './components/modals/StepsModal'
import UploadDocModal from './components/modals/UploadDocModal'
import ThanksForRegistrationModal from './components/modals/ThanksForRegistrationModal'
import Login from './components/pages/login/Login'
import LkInformation from './components/pages/lk-information/LkInformation'
import LkRestTime from './components/pages/lk-rest-time/LkRestTime'
import LkOperatorRequests from './components/pages-operator/LkOperatorRequests'
import {makeStyles} from '@material-ui/core/styles'
import LkOperatorTaksoparkaTravelLists
  from './components/pages-operator-taksopark/LkOperatorTaksoparkaTravelLists'
import LkSpecialistActiveRequests
  from './components/pages-specialist/lk-specialist-active-requests/LkSpecialistActiveRequests'
import LkMedworkerActiveRequests
  from './components/pages-medworker/LkMedworkerActiveRequests'


import AddOrganisation from "./components/modals/AddOrganisationModal";

import LkOperatorOrganizations from './components/pages-operator/LkOperatorOrganizations'
import LkOperatorEmployees from './components/pages-operator/LkOperatorEmployees'
import LkOperatorTaksoparkaEmployees from './components/pages-operator-taksopark/LkOperatorTaksoparkaEmployees'
import LkOperatorTaksoparkaTs from './components/pages-operator-taksopark/LkOperatorTaksoparkaTs'
import LkOperatorTaksoparkaOrganizations from './components/pages-operator-taksopark/LkOperatorTaksoparkaOrganizations'
import LkMedworkerActiveRequestsHistory from './components/pages-medworker/LkMedworkerActiveRequestsHistory'
import SelectRequesterByType from './components/modals/SelectRequesterByType'
import ConnectApplication from "./components/modals/ConnectApplication";
import LkOperatorUnload from './components/pages-operator/LkOperatorUnload'
import LkOperatorAnalitics from './components/pages-operator/LkOperatorAnalitics'
import TrustedOrganizationModal from './components/modals/TrustedOrganizationModal'
import LkOperatorTaksoparkDetail from './components/pages-operator-taksopark/LkOperatorTaksoparkDetail'
import DeleteTravelListModal from './components/modals/DeleteTravelListModal'
import AddAccreditationModal from './components/modals/AddAccreditationModal'
import DeleteRowModal from './components/modals/DeleteRowModal'
import RevokeAccreditationModal from './components/modals/RevokeAccreditationModal'
import EmployeeCardModal from './components/modals/EmployeeCardModal'


function App() {
  return (
    <Router>
      <Switch>
        <Route path='/login_page'><Login /></Route>
        <Route path='/lk_taksopark'><LkTaksopark/></Route>
        <Route path='/lk_personal_tab'><LkPersonalTab/></Route>
        <Route path='/modal_get_id'><WayToGetIdModal/></Route>
        <Route path='/registration'><Registration /></Route>
        <Route path='/load-page'><LoadPage /></Route>
        <Route path='/lk-travel-list'><LkTravelList /></Route>
        <Route path='/lk-documents'><LkDocuments /></Route>
        <Route path='/lk-messages'><LkMessages /></Route>
        <Route path='/lk-doc-view'><LkViewDoc /></Route>
        <Route path='/information'><LkInformation /></Route>
        <Route path='/rest'><LkRestTime /></Route>

        <Route path='/modal/new-password'><NewPasswordModal /></Route>
        <Route path='/modal/medical-organization'><MedicalOrganizationModal /></Route>
        <Route path='/modal/feedback'><FeedbackModal /></Route>
        <Route path='/modal/check-code'><CheckCodeModal /></Route>
        <Route path='/modal/car-data'><CarDataModal /></Route>
        <Route path='/modal/send-message'><SendMessageModal /></Route>
        <Route path='/modal/restore-password'><RestorePasswordModal /></Route>
        <Route path='/modal/remove-doc'><RemoveDocModal /></Route>
        <Route path='/modal/park-name'><ParkNameModal /></Route>
        <Route path='/modal/organization-ts'><OrganizationTsModal /></Route>
        <Route path='/modal/terms'><TermsModal /></Route>
        <Route path='/modal/first-step'><StepsModal /></Route>
        <Route path='/modal/upload'><UploadDocModal /></Route>
        <Route path='/modal/thanks'><ThanksForRegistrationModal /></Route>

        {/*operator*/}
        <Route path='/operator/lk-requests'><LkOperatorRequests /></Route>
        <Route path='/operator/lk-organizations'><LkOperatorOrganizations /></Route>
        <Route path='/operator/lk-employees'><LkOperatorEmployees /></Route>
        <Route path='/operator/lk-unloads'><LkOperatorUnload /></Route>
        <Route path='/operator/lk-analitics'><LkOperatorAnalitics /></Route>

        <Route path='/modal/create-employee'><EmployeeCardModal isCreate /></Route>
        <Route path='/modal/add-organisation'><AddOrganisation /></Route>
        <Route path='/modal/tax-applicant'><SelectRequesterByType /></Route>
        <Route path='/modal/connecting-application'><ConnectApplication /></Route>


        {/*operator-taksoparka*/}
        <Route path='/operator-taksoparka/lk-travel-list'><LkOperatorTaksoparkaTravelLists /></Route>
        <Route path='/operator-taksoparka/employees'><LkOperatorTaksoparkaEmployees /></Route>
        <Route path='/operator-taksoparka/ts'><LkOperatorTaksoparkaTs /></Route>
        <Route path='/operator-taksoparka/organizations'><LkOperatorTaksoparkaOrganizations /></Route>
        <Route path='/operator-taksoparka/taksopark-detail'><LkOperatorTaksoparkDetail /></Route>

        <Route path='/modal/trusted-organization'><TrustedOrganizationModal /></Route>
        <Route path='/modal/add-accreditation'><AddAccreditationModal /></Route>
        <Route path='/modal/delete-travel-list'><DeleteTravelListModal /></Route>
        <Route path='/modal/delete-row'><DeleteRowModal /></Route>
        <Route path='/modal/revoke-accreditation'><RevokeAccreditationModal /></Route>
        <Route path='/modal/card-employee'><EmployeeCardModal /></Route>

        {/*medworker*/}
        <Route path='/medworker/lk-active-request'><LkMedworkerActiveRequests /></Route>
        <Route path='/medworker/lk-active-request-history'><LkMedworkerActiveRequestsHistory /></Route>

        {/*specialist*/}
        <Route path='/specialist/lk-active-request'><LkSpecialistActiveRequests /></Route>

        <Route path='/'><ListPages/></Route>
      </Switch>
    </Router>
  )
}

const styles = {
  Root: {
    display: 'flex',
    background: 'gray',
    width: '100vw',
    minHeight: '100vh',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
  },
  Nav: {
    margin: 20,
    padding: '20px 50px',
    background: 'white',
    width: '46%',
    borderRadius: 10
  }
}

const useStyles = makeStyles(styles)

const ListPages = () => {
  const classes = useStyles()

  return (
    <div className={classes.Root}>
      <nav className={classes.Nav}>
        <h1>Водитель</h1>
        <ul>
          <li><Link to='/information'>Справочная информация</Link></li>
          <li><Link to='/load-page'>Страница загрузки</Link></li>
          <li><Link to='/login_page'>Страница входа</Link></li>
          <li><Link to='/registration'>Страница регистрации</Link></li>
          <li><Link to='/lk_personal_tab'>Личный кабинет(личные данные и документы) + Личный кабинет редакт</Link></li>
          <li><Link to='/lk-doc-view'>Регистрация и скан документа + Регистрация и скан документа 2 + Регистрация добавление элемента</Link></li>
          <li><Link to='/lk_taksopark'>Личный кабинет таксопарки</Link></li>
          <li><Link to='/lk-travel-list'>Личный кабинет медосмотр + Личный кабинет отправить заявку + Личный кабинет заявки обработаны(ЛК Путевой лист)</Link></li>
          <li><Link to='/lk-documents'>Документы</Link></li>
          <li><Link to='/lk-messages'>Сообщения</Link></li>
          <li><Link to='/rest'>Время отдыха</Link></li>

          <br/>
          <h3>модалки:</h3>
          <li><Link to='/modal/new-password'>Восстановление пароля 3(новый пароль)</Link></li>
          <li><Link to='/modal/check-code'>Восстановление пароля 2(ввод кода)</Link></li>
          <li><Link to='/modal/restore-password'>Восстановление пароля(телефон или email)</Link></li>

          <li><Link to='/modal_get_id'>Способ(Диалог получения ID)</Link></li>
          <li><Link to='/modal/send-message'>Callback(Отправить сообщение)</Link></li>
          <li><Link to='/modal/upload'>Добавить документ(Загрузить документ)</Link></li>
          <li><Link to='/modal/remove-doc'>Данет(Удалить документ)</Link></li>

          <li><Link to='/modal/car-data'>Трансп(Данные транспортного средства)</Link></li>
          <li><Link to='/modal/park-name'>Транскомп(Наименование таксопрака для поиска)</Link></li>
          <li><Link to='/modal/medical-organization'>Медицина(Организация для прохождения мед. осмотра)</Link></li>
          <li><Link to='/modal/organization-ts'>ТО(Организация для технического обслуживания)</Link></li>

          <li><Link to='/modal/terms'>Пользовательского соглашения</Link></li>
          <li><Link to='/modal/first-step'>Шаги</Link></li>
          <li><Link to='/modal/thanks'>Спасибо за регистрацию</Link></li>
          <li><Link to='/modal/feedback'>Обратной связи(Заказать звонок обратной связи)</Link></li>
        </ul>
      </nav>

      <nav className={classes.Nav}>
        <h1>Оператор</h1>
        <ul>
          <li><Link to='/operator/lk-requests'>Личный кабинет(Вкладка заявления)</Link></li>
          <li><Link to='/operator/lk-organizations'>Личный кабинет организации</Link></li>
          <li><Link to='/operator/lk-employees'>Пользователи(Вкладка сотрудники)</Link></li>
          <li><Link to='/operator/lk-unloads'>Личный кабинет выгрузки</Link></li>
          <li><Link to='/operator/lk-analitics'>Аналитические отчеты</Link></li>

          <br />
          <h3>модалки:</h3>
          <li><Link to='/modal/create-employee'>Создание сотрудника</Link></li>
          <li><Link to='/modal/add-organisation'>Добавить организции</Link></li>

          <li><Link to='/modal/tax-applicant'>Юр Физ Ип(заявитель по налогообложению)</Link></li>
          <li><Link to='/modal/tax-applicant'>Юр Физ Ип2(хз чем отличается)</Link></li>
          <li><Link to='/modal/connecting-application'>звявления_ID (заявление на подключение к системе)</Link></li>
        </ul>
      </nav>

      <nav className={classes.Nav}>
        <h1>Оператор таксопарка</h1>
        <ul>
          <li><Link to='/operator-taksoparka/lk-travel-list'>Личный кабинет(Путевые листы)</Link></li>
          <li><Link to='/operator-taksoparka/employees'>Личный кабинет сотрудники</Link></li>
          <li><Link to='/operator-taksoparka/ts'>Транссредства</Link></li>
          <li><Link to='/operator-taksoparka/organizations'>Доверенные организации</Link></li>
          <li><Link to='/operator-taksoparka/taksopark-detail'>Данные организации</Link></li>

          <br />
          <h3>модалки:</h3>
          <li><Link to='/modal/trusted-organization'>Довер орг(Доверенная организация)</Link></li>
          <li><Link to='/modal/add-accreditation'>Добавить аккредитацию</Link></li>
          <li><Link to='/modal/delete-travel-list'>Удалить путевой лист</Link></li>
          <li><Link to='/modal/delete-row'>Удалить запись</Link></li>
          <li><Link to='/modal/revoke-accreditation'>Отозвать аккредитацию</Link></li>
          <li><Link to='/modal/card-employee'>Карточка сотрудника</Link></li>
        </ul>
      </nav>

      <nav className={classes.Nav}>
        <h1>Медработник</h1>
        <ul>
          <li><Link to='/medworker/lk-active-request'>Личный кабинет запросы</Link></li>
          <li><Link to='/medworker/lk-active-request-history'>Личный кабинет4 история(История запросов)</Link></li>
        </ul>
      </nav>

      <nav className={classes.Nav}>
        <h1>Специалист-техник</h1>
        <ul>
          <li><Link to='/medworker/lk-active-request'>Личный кабинет запросы</Link></li>
          <li><Link to='/medworker/lk-active-request-history'>Личный кабинет4 история(История запросов)</Link></li>
        </ul>
      </nav>
    </div>
  )
}

export default App;
