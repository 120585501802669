import React from 'react'
import * as PropTypes from 'prop-types'
import { colors } from '../styles/colors'
import { ReactComponent as PlusIcon } from '../assets/icons/plus.svg'
import {makeStyles} from "@material-ui/core/styles";
import {getClasses} from '../helpers/mergeStylesHelper'

const styles = {
  Root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
    cursor: 'pointer',
    border: 0,
    background: 'none',
    padding: '8px',
    marginLeft: '20px'
  },
  Icon: {
    height: 74,
    width: 74
  },
  Text: {
    textAlign: 'center',
    fontSize: 12,
    padding: 10,
    color: colors.red
  }
}

const useStyles =  makeStyles(theme => styles);

export default function CardAddButton(props) {
  const classes = getClasses(props.classes, useStyles(), styles)

  return (
    <button className={classes.Root}>
      <PlusIcon className={classes.Icon} />

      <span className={classes.Text}>
        { props.text }
      </span>
    </button>
  )
}

CardAddButton.propTypes = {
  text: PropTypes.string
}
