import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Lk from '../../lk/Lk'
import LkHeader from '../../lk/LkHeader'
import LkHeaderAppend from '../../lk/LkHeaderAppend'
import LkBody from '../../lk/LkBody'
import { SlideArrow } from '../../../styles/sharable'
import clsx from 'clsx'
import {ReactComponent as RightArrowIcon} from '../../../assets/icons/arrow-right.svg'
import {ReactComponent as LeftArrowIcon} from '../../../assets/icons/arrow-left.svg'
import Button from '../../Button'
import LkTaksoparkBlock from './LkTaksoparkBlock'
import CardAddButton from '../../CardAddButton'
import LkHeaderWorkStatus from '../../lk/LkHeaderWorkStatus'

const styles = {
  Body: {
    padding: "66px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  Items: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
}
const useStyles = makeStyles(styles)

const slideArrowStyles = {
  ...SlideArrow,
  Left: {
    ...SlideArrow.Left,
    left: 0
  },
  Right: {
    ...SlideArrow.Right,
    right: 0
  }
}

const buttonStyles = {
  Root: {
    padding: "15px 20px",
    marginTop: "50px"
  }
}

export default function LkTaksopark(props) {
  const classes = useStyles(props)
  const slideArrowClasses = makeStyles(slideArrowStyles)()
  const buttonClasses = makeStyles(buttonStyles)()

  return (
    <Lk>
      <LkHeader append={
        <LkHeaderAppend
          title={'Личный кабинет'}
          backLink={'/some'}
          rightSlot={<LkHeaderWorkStatus type={'start'} value={'Начать работу'}/>}
        />
      }/>

      <LkBody activeTab={'ts'}>
        <div className={classes.Body}>
          <button className={clsx(slideArrowClasses.Right, slideArrowClasses.Root)}>
            <RightArrowIcon />
          </button>

          <button className={clsx(slideArrowClasses.Left, slideArrowClasses.Root)}>
            <LeftArrowIcon />
          </button>

          <div className={classes.Items}>
            <LkTaksoparkBlock />
            <LkTaksoparkBlock />
            <CardAddButton text={'Добавить таксопарк'} />
          </div>

          <div>
            <Button
              color={'red'}
              classes={buttonClasses}
              text={'Перейти к медосмотру и ТО'}
            />
          </div>
        </div>
      </LkBody>
    </Lk>
  )
}