import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import LkDocumentsDoc from './LkDocumentsDoc'

const styles = {
  Root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Items: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    margin: "0 auto"
  }
}
const useStyles = makeStyles(theme => styles)

export default function LkDocumentsDocs(props) {
  const classes = useStyles(props)
  const docs = [
    {
      img: require('../../../assets/images/scan1.png'),
      title: 'Водительское удостоверение',
      date: '01.01.2019 - 01.01.2029',
      number: 'ПЛ-148753'
    },
    {
      title: 'Путевой лист',
      date: '01.01.2019 - 01.01.2029',
      number: 'ПЛ-148753'
    },
    {
      title: 'Путевой лист',
      date: '01.01.2019 - 01.01.2029',
      number: 'ПЛ-148753'
    },
    {
      title: 'Миграционная карта',
      date: '01.01.2019 - 01.01.2029',
      number: 'ПЛ-148753'
    },
    {
      img: require('../../../assets/images/scan4.png'),
      title: 'Путевой лист',
      date: '01.01.2019 - 01.01.2029',
      number: 'ПЛ-148753'
    },
    {
      img: require('../../../assets/images/scan1.png'),
      title: 'Водительское удостоверение',
      date: '01.01.2019 - 01.01.2029',
      number: 'ПЛ-148753'
    },
    {
      title: 'Путевой лист',
      date: '01.01.2019 - 01.01.2029',
      number: 'ПЛ-148753'
    },
    {
      title: 'Путевой лист',
      date: '01.01.2019 - 01.01.2029',
      number: 'ПЛ-148753'
    },
    {
      title: 'Миграционная карта',
      date: '01.01.2019 - 01.01.2029',
      number: 'ПЛ-148753'
    },
    {
      img: require('../../../assets/images/scan4.png'),
      title: 'Путевой лист',
      date: '01.01.2019 - 01.01.2029',
      number: 'ПЛ-148753'
    },
    {
      img: require('../../../assets/images/scan1.png'),
      title: 'Водительское удостоверение',
      date: '01.01.2019 - 01.01.2029',
      number: 'ПЛ-148753'
    },
    {
      title: 'Путевой лист',
      date: '01.01.2019 - 01.01.2029',
      number: 'ПЛ-148753'
    },
    {
      title: 'Путевой лист',
      date: '01.01.2019 - 01.01.2029',
      number: 'ПЛ-148753'
    },
    {
      title: 'Миграционная карта',
      date: '01.01.2019 - 01.01.2029',
      number: 'ПЛ-148753'
    },
    {
      img: require('../../../assets/images/scan4.png'),
      title: 'Путевой лист',
      date: '01.01.2019 - 01.01.2029',
      number: 'ПЛ-148753'
    }
  ]

  return (
    <div className={classes.Root}>
      <div className={classes.Items}>
        { docs.map((doc, index) =>
          <LkDocumentsDoc key={index} doc={doc} />
        )}
      </div>
    </div>
  )
}