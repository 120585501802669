import {makeStyles} from '@material-ui/core/styles'

export const getInputClass = (name, inputStyles) => {
  if (!name) return ''

  return makeStyles({
    root: {
      ...inputStyles.Input,
      ...(inputStyles[name] ? inputStyles[name] : {})
    }
  })()
}

