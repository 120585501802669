import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Lk from '../../lk/Lk'
import LkHeader from '../../lk/LkHeader'
import clsx from 'clsx'
import {ReactComponent as FirstStepIcon} from '../../../assets/icons/first-step-icon.svg'
import {ReactComponent as StepCircleIcon} from '../../../assets/icons/step-circle.svg'
import {ReactComponent as ArrowIcon} from '../../../assets/icons/next.svg'
import {ReactComponent as SecondStepIcon} from '../../../assets/icons/second-step-icon.svg'
import {ReactComponent as ThirdStepIcon} from '../../../assets/icons/car.svg'
import {ReactComponent as FourthStepIcon} from '../../../assets/icons/document2.svg'
import {StepsStyles} from '../../../styles/sharable'
import LkHeaderAppend from '../../lk/LkHeaderAppend'
import {colors} from '../../../styles/colors'
import {fontSizes} from '../../../styles/font-sizes'
import {fontFamily} from '../../../styles/font-family'

const styles = {
  ...StepsStyles,
  Steps: {
    ...StepsStyles.Steps,
    width: '80%'
  },
  StepsWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "100px"
  },
  FourthStep: {
    width: 0
  },
  StepDescrFourthStep: {
    top: 35,
    right: -25
  },
  StepIconFourthStep: {
    bottom: 40,
    right: 10
  },
  Title: {
    color: colors.red,
    fontSize: fontSizes.medium,
    marginTop: "50px"
  },
  DocTitle: {
    marginTop: "180px",
    textAlign: "center",
    fontSize: fontSizes.medium,
    fontFamily: fontFamily.medium,
    width: "70%"
  },
  Doc: {
    width: "70%",
    height: "auto",
    maxHeight: "455px",
    overflowY: "auto",
    backgroundColor: colors.white,
    marginTop: "40px",
    fontSize: "12px",
    borderTop: "29px solid white",
    borderRight: "32px solid white",
    borderBottom: "22px solid white",
    borderLeft: "30px solid white"
  }
}
const useStyles = makeStyles(styles)

export default function LkInformation(props) {
  const classes = useStyles(props)

  return (
    <Lk>
      <LkHeader append={
        <LkHeaderAppend title={'Справочная информация'} back-link={'/some'} />
      }/>

      <div className={classes.Title}>
        Схема работы
      </div>

      <div className={classes.StepsWrapper}>
        <div className={classes.Steps}>
          <div className={clsx(classes.Step, classes.StepActive, classes.FirstStep)}>
            <FirstStepIcon className={classes.StepIcon} />
            <StepCircleIcon />
            <div className={classes.StepDescr}>
              Заполните поля формы
            </div>
          </div>

          <div className={clsx(classes.Line, classes.LineActive)}>
            <div className={classes.Arrow}>
              <ArrowIcon className={classes.ArrowIcon} />
            </div>
          </div>

          <div className={clsx(classes.SecondStep, classes.Step)}>
            <SecondStepIcon className={classes.StepIcon} />
            <StepCircleIcon />
            <div className={classes.StepDescr}>
              <div>Получите Ваш персональный ID</div>
            </div>
          </div>

          <div className={classes.Line}>
            <div className={classes.Arrow}>
              <ArrowIcon className={classes.ArrowIcon} />
            </div>
          </div>

          <div className={clsx(classes.ThirdStep, classes.Step)}>
            <ThirdStepIcon className={classes.StepIcon} />
            <StepCircleIcon />
            <div className={classes.StepDescr}>
              <div> Используя ID добавьте информацию о таксопарках и т. д.</div>
            </div>
          </div>

          <div className={classes.Line}>
            <div className={classes.Arrow}>
              <ArrowIcon className={classes.ArrowIcon} />
            </div>
          </div>

          <div className={clsx(classes.FourthStep, classes.Step)}>
            <FourthStepIcon className={clsx(classes.StepIcon, classes.StepIconFourthStep)} />
            <div className={clsx(classes.StepDescr, classes.StepDescrFourthStep)}>
              <div>Путевой лист</div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.DocTitle}>
        Приказ Минтранса России от 20.08.2004 N 15 (ред. от 07.08.2019) "Об утверждении Положения об особенностях режима
        рабочего времени и времени отдыха водителей автомобилей" (Зарегистрировано в Минюсте России 01.11.2004 N 6094)
      </div>

      <div className={classes.Doc}>
        II. Рабочее время
        <br />
        6. В течение рабочего времени водитель должен исполнять свои трудовые обязанности в соответствии с условиями трудового договора, правилами внутреннего трудового распорядка организации и графиком работы (сменности).
        <br />
        7. Нормальная продолжительность рабочего времени водителей не может превышать 40 часов в неделю.
        Для водителей, работающих по календарю пятидневной рабочей недели с двумя выходными днями, нормальная продолжительность ежедневной работы (смены) не может превышать 8 часов, а для работающих по календарю шестидневной рабочей недели с одним выходным днем - 7 часов.
        <br />
        8. В тех случаях, когда по условиям производства (работы) не может быть соблюдена установленная нормальная ежедневная или еженедельная продолжительность рабочего времени, водителям устанавливается суммированный учет рабочего времени с продолжительностью учетного периода один месяц. Продолжительность учетного периода может быть увеличена до трех месяцев по согласованию с выборным органом первичной профсоюзной организации, а при ее отсутствии - с иным представительным органом работников.
        (в ред. Приказа Минтранса России от 05.06.2017 N 212)
        (см. текст в предыдущей редакции)
        На перевозках пассажиров в курортной местности в летне-осенний период и на других перевозках, связанных с обслуживанием сезонных работ, учетный период может устанавливаться продолжительностью до 6 месяцев.
        Продолжительность рабочего времени за учетный период не должна превышать нормального числа рабочих часов.
        Суммированный учет рабочего времени вводится работодателем с учетом мнения представительного органа работников.
        <br />
        9. При суммированном учете рабочего времени продолжительность ежедневной работы (смены) водителей не может превышать 10 часов, за исключением случаев, предусмотренных пунктами 10, 11, 12 Положения.
        <br />
        10. В случае, когда при осуществлении междугородной перевозки водителю необходимо дать возможность доехать до соответствующего места отдыха, продолжительность ежедневной работы (смены) может быть увеличена до 12 часов при условии, если не превышается время управления автомобилем, предусмотренное пунктами 16 и 17 настоящего Положения.
        (в ред. Приказа Минтранса России от 03.05.2018 N 170)
        (см. текст в предыдущей редакции)
      </div>
    </Lk>
  )
}