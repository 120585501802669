import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Header from '../header/Header'
import {ReactComponent as MenuIcon} from '../../assets/icons/menu.svg'
import LkHeaderProfile from './LkHeaderProfile'

const styles = {
  Root: {},
  MenuButton: {
    border: "none",
    background: "none",
    cursor: "pointer",
    zIndex: 9999
  },
  Profile: {
    marginBottom: "35px",
    marginLeft: "40px"
  },
  MenuIcon: {
    marginBottom: "35px",
    marginRight: "40px"
  }
}
const useStyles = makeStyles(styles)

export default function LkHeader(props) {
  const classes = useStyles(props)

  return (
    <Header
      name={props.title || 'водитель'}
      leftSlot={
        <LkHeaderProfile />
      }
      rightSlot={
        <div>
          <button className={classes.MenuButton}>
            <MenuIcon />
          </button>
          {/*<LkMenu />*/}
        </div>
      }
      append={props.append}
    />
  )
}