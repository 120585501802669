import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {getClasses} from '../../helpers/mergeStylesHelper'
import Lk from '../lk/Lk'
import LkHeader from '../lk/LkHeader'
import LkHeaderAppend from '../lk/LkHeaderAppend'
import LkBody from '../lk/LkBody'
import LkCard from '../lk/LkCard'
import Select from '../Select'
import Button from '../Button'
import {ReactComponent as UploadIcon} from '../../assets/icons/upload.svg'
import clsx from 'clsx'
import {colors} from '../../styles/colors'


const styles = {
  Root: {},
  CardBody: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  CardRoot: {
    width: 365,
    height: 365,
    padding: 44,
    justifyContent: 'space-between',
  },
  UploadIcon: {
    margin: '20px 0',
    width: 50,
    height: 50,
  },
  UploadIconCenter: {
    margin: '20px 0 60px 0'
  },
  UploadIconRed: {
    '& path': {
      stroke: colors.red
    }
  },
  UploadIconBlue: {
    transform: 'rotate(180deg)',
    '& path': {
      stroke: colors.blue
    }
  },
}

const useStyles = makeStyles(styles)

export default function LkOperatorUploads(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)


  return (
    <Lk classes={classes}>
      <LkHeader title={'оператор'} append={
        <LkHeaderAppend
          title='Личный кабинет'
          backLink={'/some'}
        />
      } />

      <LkBody activeTab={'uploads'} type={'operator'}>
        <LkCard
          showDelete={false}
          title={'Выгрузить данные водителей на проверку ВУ'}
          classes={{ Body: classes.CardBody, Root: classes.CardRoot }}
        >
          <Select
            options={[
              {id: 1, name: 'Россия'},
              {id: 2, name: 'Германия'}
            ]}
            fullWidth
            inputId={'unload'}
            label={'Выбрать страну'}
          />
          <UploadIcon className={clsx(classes.UploadIconRed, classes.UploadIcon)} />
          <Button text={'Выгрузить'} size={'max'} />
        </LkCard>

        <LkCard
          showDelete={false}
          title={'Загрузить результаты проверки данных водителей'}
          classes={{ Body: classes.CardBody, Root: classes.CardRoot }}
        >
          <Select
            options={[
              {id: 1, name: 'Россия'},
              {id: 2, name: 'Германия'}
            ]}
            fullWidth
            inputId={'unload'}
            label={'Выбрать страну'}
          />
          <UploadIcon className={clsx(classes.UploadIconBlue, classes.UploadIcon)} />
          <Button text={'Загрузить'} size={'max'} color={'blue'} />
        </LkCard>

        <LkCard
          showDelete={false}
          title={'Выгрузить данные водителей на проверку в миграционной службе'}
          classes={{ Body: classes.CardBody, Root: classes.CardRoot }}
        >
          <UploadIcon className={clsx(classes.UploadIconRed, classes.UploadIcon, classes.UploadIconCenter)} />
          <Button text={'Выгрузить'} size={'max'} />
        </LkCard>
      </LkBody>
    </Lk>
  )
}