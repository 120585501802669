import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {getClasses} from '../../helpers/mergeStylesHelper'
import Lk from '../lk/Lk'
import LkHeader from '../lk/LkHeader'
import LkHeaderAppend from '../lk/LkHeaderAppend'
import LkBody from '../lk/LkBody'
import EntityList from '../EntityList'
import CheckboxInput from '../CheckboxInput'
import {colors} from '../../styles/colors'
import {fontSizes} from '../../styles/font-sizes'


const styles = {
  Root: {},
  CheckBoxText: {
    color: colors.black
  },
  CheckBoxRoot: {
    padding: '0 20px'
  },
  ToolbarAppend: {
   justifyContent: 'center'
  },
  ToolbarAppendCheckBoxText: {
    fontSize: fontSizes.small4
  },
  BodyBottom: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30
  }
}
const useStyles = makeStyles(styles)

export default function LkOperatorEmployees(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)

  const tableData = [
    {
      id: '1234561231234',
      fio: 'Иванов Игорь Иванович',
      status: 'Новое',
      role: 'Водитель',
    },
    {
      id: '1234561231234',
      fio: 'Иванов Игорь Иванович',
      status: 'Новое',
      role: 'Водитель',
    },
    {
      id: '1234561231234',
      fio: 'Иванов Игорь Иванович',
      status: 'Новое',
      role: 'Водитель',
    },
    {
      id: '1234561231234',
      fio: 'Иванов Игорь Иванович',
      status: 'Новое',
      role: 'Водитель',
    },
    {
      id: '1234561231234',
      fio: 'Иванов Игорь Иванович',
      status: 'Новое',
      role: 'Водитель',
    },
  ]

  return (
    <Lk classes={classes}>
      <LkHeader title={'оператор'} append={
        <LkHeaderAppend
          title='Личный кабинет'
          backLink={'/some'}
        />
      } />

      <LkBody activeTab={'employees'} type={'operator'}>
        <EntityList
          data={tableData}
          toolbarTopAppend={
            <CheckboxInput classes={{ Text: classes.CheckBoxText, Root: classes.CheckBoxRoot }}>
              Новые пользователи
            </CheckboxInput>
          }
          toolbarButtonText={'Добавить пользователя'}
          columns={[
            {title: 'ID', field: 'id', align: 'center'},
            {title: 'ФИО', field: 'fio', align: 'center'},
            {title: 'Статус', field: 'status', align: 'center'},
            {title: 'Роль', field: 'role', align: 'center'},
          ]}
        />
      </LkBody>
    </Lk>
  )
}