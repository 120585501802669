import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {colors} from '../../styles/colors'
import {fontFamily} from '../../styles/font-family'
import {ReactComponent as BackArrowIcon} from '../../assets/icons/back-arrow.svg'
import clsx from 'clsx'

const styles = {
  Root: {
    display: "flex",
    height: "100px",
    width: '100%'
  },
  BackArrow: {
    border: "none",
    background: "transparent",
    cursor: "pointer",
    '& path': {
      stroke: colors.black
    }
  },
  SideBlock: {
    width: "25%",
    display: "flex",
    alignItems: "center"
  },
  Left: {
    justifyContent: "flex-start",
    paddingLeft: "35px"
  },
  Right: {
    justifyContent: "flex-end",
    paddingRight: "35px"
  },
  Center: {
    width: "50%",
    textAlign: "center",
    fontSize: "25px",
    color: colors.gray2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    '& h2': {
      fontFamily: fontFamily.bold
    }
  }
}
const useStyles = makeStyles(theme => styles)

export default function LkHeaderAppend(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.Root}>
      <div className={clsx(classes.Left, classes.SideBlock)}>
        {
          props.backLink &&
          <button className={classes.BackArrow}>
            <BackArrowIcon />
          </button>
        }
      </div>

      <div className={classes.Center}>
        {
          props.title &&
          <h2>
            {props.title}
          </h2>
        }
      </div>

      <div className={clsx(classes.Right, classes.SideBlock)}>
        {props.rightSlot}
      </div>
    </div>
  )
}