import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {ReactComponent as ArrowIcon} from '../../assets/icons/next.svg'
import {ReactComponent as StepCircleIcon} from '../../assets/icons/step-circle.svg'
import {ReactComponent as FirstStepIcon} from '../../assets/icons/first-step-icon.svg'
import {ReactComponent as SecondStepIcon} from '../../assets/icons/second-step-icon.svg'
import {ReactComponent as ThirdStepIcon} from '../../assets/icons/car.svg'
import {colors} from '../../styles/colors'
import Modal from '../Modal'
import Button from '../Button'
import LkUserInfoForm from '../lk/LkUserInfoForm'
import clsx from 'clsx'
import {StepsStyles} from '../../styles/sharable'

const userInfoFormStyles = {
  Root: { width: "715px" },
}
const styles = {
  ...StepsStyles
}

const modalStyles = {
  Body: {
    width: "883px",
    height: "auto",
    backgroundColor: colors.gray7,
  },
  Header: { marginTop: "120px" },
  HeadingContent: { marginTop: "60px", width: "100%" },
}

export default function StepsModal(props) {
  const classes = makeStyles(styles)()
  const modalClasses = makeStyles(modalStyles)()
  const userInfoFormClasses = makeStyles(userInfoFormStyles)()

  return (
    <Modal
      classes={modalClasses}
      headerTextSlot={
        <span> Заполните поля формы </span>
      }
      headerContentSlot={
        <div className={classes.Steps}>
          <div className={clsx(classes.Step, classes.StepActive, classes.FirstStep)}>
            <FirstStepIcon className={classes.StepIcon} />
            <StepCircleIcon />
            <div className={classes.StepDescr}>
              Заполните поля формы
            </div>
          </div>

          <div className={clsx(classes.Line, classes.LineActive)}>
            <div className={classes.Arrow}>
              <ArrowIcon className={classes.ArrowIcon} />
            </div>
          </div>

          <div className={clsx(classes.SecondStep, classes.Step)}>
            <SecondStepIcon className={classes.StepIcon} />
            <StepCircleIcon />
            <div className={classes.StepDescr}>
              <div>Получите Ваш персональный ID</div>
            </div>
          </div>

          <div className={classes.Line}>
            <div className={classes.Arrow}>
              <ArrowIcon className={classes.ArrowIcon} />
            </div>
          </div>

          <div className={clsx(classes.ThirdStep, classes.Step)}>
            <ThirdStepIcon className={classes.StepIcon} />
            <StepCircleIcon />
            <div className={classes.StepDescr}>
              <div> Используя ID добавьте информацию о таксопарках и т. д.</div>
            </div>
          </div>

        </div>
      }
      contentSlot={
        <LkUserInfoForm classes={userInfoFormClasses} />
      }
      buttonsSlot={
        <Button
          text="Далее"
          color="red"
          size="big"
        />
      }
    />
  )
}