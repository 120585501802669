import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import { colors } from '../../../styles/colors'
import { fontFamily } from '../../../styles/font-family'
import { ReactComponent as BigYellowLogo } from '../../../assets/icons/big_yellow_logo.svg'
import { ReactComponent as BigYellowLoader } from '../../../assets/icons/loader_big_yellow.svg'
import { CenteredAbsolute } from '../../../styles/sharable'

const useStyles =  makeStyles(theme => ({
  '@keyframes rotate': {
    '0%': {
      transform: 'rotateZ(0)'
    },
    '100%': {
      transform: 'rotateZ(360deg)'
    }
  },
  Loader: {
    width: 370,
    height: "calc(100vh - 320px)",
    position: "relative",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  Wrapper: {
    height: 300,
    width: 300
  },
  Text: {
    fontFamily: fontFamily.bold,
    fontSize: 24,
    textTransform: 'uppercase',
    color: colors.white,
    textAlign: 'center'
  },
  BigYellowLoader: {
    ...CenteredAbsolute,
    top: -44,
    animation: '$rotate linear 1s infinite',
  }
}));

export default function LoadPageLoader(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.Loader}>
      <div className={classes.Wrapper}>
        <BigYellowLogo />
        <BigYellowLoader className={classes.BigYellowLoader} />
        <h1 className={classes.Text}>
          Контроль работы
          <br />такси
        </h1>
      </div>
    </div>
  )
}
