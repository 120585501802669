import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Lk from '../../lk/Lk'
import LkHeader from '../../lk/LkHeader'
import LkHeaderAppend from '../../lk/LkHeaderAppend'
import LkHeaderWorkStatus from '../../lk/LkHeaderWorkStatus'
import {fontSizes} from '../../../styles/font-sizes'
import {fontFamily} from '../../../styles/font-family'
import {colors} from '../../../styles/colors'

const styles = {
  Label: {
    fontSize: fontSizes.large,
    fontFamily: fontFamily.bold,
    color: colors.gray2
  }
}
const useStyles = makeStyles(styles)

export default function LkRestTime(props) {
  const classes = useStyles(props)
  const timer = '15:59:02'

  return (
    <Lk>
      <LkHeader append={
        <LkHeaderAppend back-link={'/some'} rightSlot={
          <LkHeaderWorkStatus type={'rest'} value={timer} />
        }/>
      } />

      <div className={classes.Label}>
        Время отдыха
      </div>
    </Lk>
  )
}