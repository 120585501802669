import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../../header/Header'
import { media } from '../../../styles/media'
import { ReactComponent as BackArrowIcon } from '../../../assets/icons/back-arrow.svg'
import { ReactComponent as CoddPhoneIcon }  from '../../../assets/icons/codd-phone.svg'
import { colors } from '../../../styles/colors'
import {fontFamily} from '../../../styles/font-family'
import {fontSizes} from '../../../styles/font-sizes'

const useHeaderStyles = makeStyles({
  Body: {
    [media[768]]: {
      justifyContent: 'flex-start'
    },
    [media[500]]: {
      flexDirection: 'column'
    }
  },
  BodyBlock: {
    width: '28%',
  },
  BodyLeft: {
    [media[768]]: {
      width: '10%'
    },
    [media[500]]: {
      justifyContent: 'center',
      width: '100%',
      marginTop: '30px'
    }
  },
  BodyCenter: {
    width: '44%',
    [media[768]]: {
      width: '80%',
      justifyContent: 'center'
    },
    [media[500]]: {
      width: '100%'
    }
  },
  BodyRight: {
    [media[768]]: {
      width: '100%',
      justifyContent: 'center'
    }
  }
})

const useStyles = makeStyles({
  BackArrow: {
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    '& path': {
      stroke: colors.white
    }
  },

  Phone: {
    color: colors.orange,
    fontFamily: fontFamily.bold,
    fontSize: fontSizes.medium,
    textDecoration: "none",
    marginLeft: "8px",
    marginTop: "7px"
  }
})

function LeftSlot({ classes }) {
  return (
    <button className={classes.BackArrow}>
      <BackArrowIcon />
    </button>
  )
}

function RightSlot({ classes }) {
  return (
    <div>
      <CoddPhoneIcon />
      <a className={classes.Phone} href='tel:3210'>
        3210
      </a>
    </div>
  )
}

export default function LoadPageFooter(props) {
  const headerClasses = useHeaderStyles(props)
  const classes = useStyles(props)

  return (
    <Header
      classes={headerClasses}
      leftSlot={<LeftSlot classes={classes} />}
      rightSlot={<RightSlot classes={classes} />}
    />
  )
}
