import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {colors} from '../../../styles/colors'
import Card from '../../Card'
import {ReactComponent as NotLoadedIcon} from '../../../assets/icons/not-loaded.svg'

const styles = {
  Root: {
    margin: "0 15px",
  },
  Title: {
    padding: "0 10px",
    fontSize: "14px",
    color: colors.gray13,
    textAlign: "center"
  },
  Img: {
    minHeight: "155px",
    maxHeight: "155px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  Date: { paddingTop: "20px", fontSize: "12px" }
}

const cardStyles = {
  Root: {
    justifyContent: "center",
    alignItems: "center",
  },
}

const useStyles = makeStyles(theme => styles)
const useCardStyles = makeStyles(theme => cardStyles)

export default function LkPersonalTabScan(props) {
  const classes = useStyles(props)
  const cardClasses = useCardStyles(props)

  if(!props.scan) return null

  return (
    <Card classes={cardClasses}>
      <div className={classes.Img}>
        { props.scan.loaded
          ? <img src={props.scan.img} alt="" />
          : <NotLoadedIcon />
        }
      </div>

      <div className={classes.Title}>
        {props.scan.title}
      </div>

      <div className={classes.Date}>
        <span>
          { props.scan.loaded ? props.scan.date : 'не загружено'}
        </span>
      </div>
    </Card>
  )
}