import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Modal from '../Modal'
import TextField from '../TextField'
import Button from '../Button'
import {colors} from '../../styles/colors'
import {fontSizes} from '../../styles/font-sizes'
import {getClasses} from "../../helpers/mergeStylesHelper";
import CheckboxInput from "../CheckboxInput";

const styles = {
    TopWrapper: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap"
    },

    MiddleWrapper: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        marginTop: "50px"
    },

    CheckboxIP: {
        padding: "10px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: 'center',
        width: "50%",
        marginBottom: "60px"
    },

    CheckboxTaxi: {
        padding: "10px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: 'center',
        width: "27%",
    },

    CheckboxMed: {
        padding: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        width: "33%",
    },

    CheckboxTech: {
        padding: "10px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: 'center',
        width: "40%",
    },

    CheckboxText: {
        color: colors.black,
        fontSize: fontSizes.small,
        marginBottom: 0
    },

    CheckboxWrapper: {
        color: colors.red
    },

    Buttons: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        marginTop: "40px",
        paddingBottom: "30px"
    },
}

const modalStyles = {
    Body: {
        width: "873px",
        height: "auto",
        backgroundColor: colors.gray7,
    },
    Content: {
        display: "flex",
        flexWrap: 'wrap',
        flexDirection: "column",
        width: "100%"
    },
    Header: {color: colors.gray2},
    Buttons: {
        width: "100%",
    }
}

const inputStyles = {
    Input: {
        margin: "10px",
        minWidth: "100px"
    },
    InputFullName: {width: "calc(55% - 20px)"},
    InputShortName: {width: "calc(45% - 20px)"},
    InputFactAddress: {width: "calc(45% - 20px)"},
    InputLegalAddress: {width: "calc(55% - 20px)"},
    InputOGRN: {width: "calc(30% - 20px)"},
    InputINN: {width: "calc(25% - 20px)"},
    InputKPP: {width: "calc(20% - 20px)"},
    InputPhone: {width: "calc(25% - 20px)"},
    InputManager: {width: "calc(40% - 20px)"},
    InputSite: {width: "calc(35% - 20px)"},
    InputEmail: {width: "calc(25% - 20px)"},
}

const useStyles = makeStyles(styles)
const useModalStyles = makeStyles(modalStyles)

export default function AddOrganisation(props) {
    const classes = getClasses(props.classes, useStyles(props), styles)
    const modalClasses = useModalStyles()

    const getInputClass = (name) => {
        if (!name) return ''

        return makeStyles({
            root: {
                ...inputStyles.Input,
                ...(inputStyles[name] ? inputStyles[name] : {})
            }
        })()
    }

    return <Modal
        classes={modalClasses}
        headerTextSlot={
            <span> Добавление юридического лица </span>
        }

        contentSlot={
            <React.Fragment>
                <div className={classes.TopWrapper}>
                    <CheckboxInput
                        classes={{Root: classes.CheckboxIP, Text: classes.CheckboxText, InputWrapper: classes.CheckboxWrapper}}>
                        Организация является ИП/самозанятым
                    </CheckboxInput>

                    <TextField
                        classes={getInputClass('InputFullName')}
                        label="Полное наименование организации"
                    />

                    <TextField
                        classes={getInputClass('InputShortName')}
                        label="Краткое наименование орг."
                        disabled
                    />

                    <TextField
                        classes={getInputClass('InputFactAddress')}
                        label="Адрес местонахождения"
                    />

                    <TextField
                        classes={getInputClass('InputLegalAddress')}
                        label="Юридический адрес"
                        disabled="true"
                    />
                </div>

                <div className={classes.MiddleWrapper}>
                    <TextField
                        classes={getInputClass('InputOGRN')}
                        label="ОГРН"
                    />

                    <TextField
                        classes={getInputClass('InputINN')}
                        label="ИНН"
                    />

                    <TextField
                        classes={getInputClass('InputKPP')}
                        label="КПП"
                    />

                    <TextField
                        classes={getInputClass('InputPhone')}
                        label="Телефон"
                    />

                    <TextField
                        classes={getInputClass('InputManager')}
                        label="Генеральный директор"
                    />

                    <TextField
                        classes={getInputClass('InputSite')}
                        label="Веб-сайт"
                    />

                    <TextField
                        classes={getInputClass('InputEmail')}
                        label="Email"
                    />

                    <CheckboxInput
                        classes={{Root: classes.CheckboxTaxi, Text: classes.CheckboxText, InputWrapper: classes.CheckboxWrapper}}>
                        Услуги такси
                    </CheckboxInput>

                    <CheckboxInput
                        classes={{Root: classes.CheckboxMed, Text: classes.CheckboxText, InputWrapper: classes.CheckboxWrapper}}>
                        Проведение медосмотров
                    </CheckboxInput>

                    <CheckboxInput
                        classes={{Root: classes.CheckboxTech, Text: classes.CheckboxText, InputWrapper: classes.CheckboxWrapper}}>
                        Проведение техосмотров
                    </CheckboxInput>
                </div>
            </React.Fragment>
        }

        buttonsSlot={
            <div className={classes.Buttons}>
                <Button
                    text="Сохранить"
                    color="blue"
                    size="big"
                />

                <Button
                    text="Отмена"
                    color="red"
                    size="big"
                />
            </div>
        }
    />
}