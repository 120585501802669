import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {colors} from '../../../styles/colors'
import {fontFamily} from '../../../styles/font-family'
import Counter from '../../Counter'

const styles = {
  Root: {
    width: "100%",
    minHeight: "130px",
    background: colors.white,
    padding: "20px 13px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: "50px",
    "&:first-child": { marginTop: "0" },
  },
  Counter: {
    position: "absolute",
    left: "10px",
    top: "-12px",
  },
  Title: {
    fontSize: "14px",
    fontFamily: fontFamily.bold
  },
  Date: { color: colors.red2 },
  Text: { fontSize: "14px" }
}
const counterStyles = {
  Root: {
    color: colors.black
  }
}
const useStyles = makeStyles(theme => styles)

export default function LkMessage(props) {
  const classes = useStyles(props)
  const counterClasses = makeStyles(counterStyles)()

  return (
    <div className={classes.Root}>
      <div className={classes.Counter}>
        <Counter classes={counterClasses} value={props.message.index} />
      </div>

      <div className={classes.Date}>
        { props.message.date }
      </div>

      <div className={classes.Title}>
        { props.message.title }
      </div>

      <div className={classes.Text}>
        { props.message.text }
      </div>
    </div>
  )
}