import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {colors} from '../../styles/colors'
import Counter from '../Counter'

const styles = {
  Root: {
    display: "flex",
    fontWeight: "bold",
    fontSize: "14px",
    height: "76px",
  },
  Info: {
    paddingLeft: "11px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  Fio: {
    color: colors.white,
    paddingBottom: "2px"
  },
  Image: {
    position: "relative",
    height: "73px",
    width: "73px",
  },
  ImageWrapper: {
    borderRadius: "50%",
    height: "76px",
    width: "76px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `2px solid ${colors.gray11}`
  },
  Photo: {
    width: "auto",
    height: "100%"
  },
  Id: {
    color: colors.orange
  },
  MessagesStatus: {
    paddingTop: "6px",
    fontSize: "12px",
    color: colors.gray3
  },
  Counter: {
    position: "absolute",
    right: "-17px",
    bottom: "-3px",
  }

}
const counterStyles = {
  Root: {
    width: "25px",
    height: "25px"
  }
}

const useStyles = makeStyles(theme => styles)
const useCounterStyles = makeStyles(theme => counterStyles)

export default function LkHeaderProfile(props) {
  const classes = useStyles(props)
  const counterClasses = useCounterStyles(props)

  return (
    <div className={classes.Root}>
      <div className={classes.Image}>
        <div className={classes.ImageWrapper}>
          <img className={classes.Photo} src={require('../../assets/images/img.png')} alt="profile" />
        </div>

        <div className={classes.Counter}>
          <Counter classes={counterClasses} value="2" />
        </div>
      </div>

      <div className={classes.Info}>
        <div>
          <div className={classes.Fio}>
            Кузнецов
          </div>

          <div className={classes.Fio}>
            Сергей Владимирович
          </div>

          <div className={classes.Id}>
            ID 01234567
          </div>
        </div>

        <div>
          <div className={classes.MessagesStatus}>
            Нет новых сообщений
          </div>
        </div>
      </div>
    </div>
  )
}