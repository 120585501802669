import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {getClasses} from '../../helpers/mergeStylesHelper'
import Lk from '../lk/Lk'
import LkHeader from '../lk/LkHeader'
import LkHeaderAppend from '../lk/LkHeaderAppend'
import LkBody from '../lk/LkBody'
import EntityList from '../EntityList'
import CheckboxInput from '../CheckboxInput'
import {colors} from '../../styles/colors'
import Button from '../Button'
import {fontSizes} from '../../styles/font-sizes'
import clsx from 'clsx'


const styles = {
  Root: {},
  CheckBoxText: {
    color: colors.black
  },
  CheckBoxRoot: {
    padding: '0 20px'
  },
  ToolbarAppend: {
   justifyContent: 'center'
  },
  ToolbarAppendCheckBoxText: {
    fontSize: fontSizes.small4
  },
  BodyBottom: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30
  }
}
const useStyles = makeStyles(styles)

export default function LkOperatorOrganizations(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)

  const tableData = [
    {
      name: 'Сити такси',
      inn: '13245678',
      orgn: '13245678',
      taksopark: false,
      medosmotr: false,
      tehosmotr: false,
    },
    {
      name: 'Сити такси',
      inn: '13245678',
      orgn: '13245678',
      taksopark: false,
      medosmotr: false,
      tehosmotr: false,
    },
    {
      name: 'Сити такси',
      inn: '13245678',
      orgn: '13245678',
      taksopark: false,
      medosmotr: false,
      tehosmotr: false,
    },
    {
      name: 'Сити такси',
      inn: '13245678',
      orgn: '13245678',
      taksopark: false,
      medosmotr: false,
      tehosmotr: false,
    },
    {
      name: 'Сити такси',
      inn: '13245678',
      orgn: '13245678',
      taksopark: false,
      medosmotr: false,
      tehosmotr: false,
    },
  ]

  return (
    <Lk classes={classes}>
      <LkHeader title={'оператор'} append={
        <LkHeaderAppend
          title='Личный кабинет'
          backLink={'/some'}
        />
      } />

      <LkBody activeTab={'organizations'} type={'operator'}>
        <EntityList
          data={tableData}
          toolbarTopAppend={
            <CheckboxInput classes={{ Text: classes.CheckBoxText, Root: classes.CheckBoxRoot }}>
              Новые организации
            </CheckboxInput>
          }
          toolbarAppendClassName={classes.ToolbarAppend}
          toolbarAppend={
            <React.Fragment>
              <CheckboxInput classes={{ Text: clsx(classes.CheckBoxText, classes.ToolbarAppendCheckBoxText), Root: classes.CheckBoxRoot }}>
                Услуги такси
              </CheckboxInput>

              <CheckboxInput classes={{ Text: clsx(classes.CheckBoxText, classes.ToolbarAppendCheckBoxText), Root: classes.CheckBoxRoot }}>
                Проведение медосмотров
              </CheckboxInput>

              <CheckboxInput classes={{ Text: clsx(classes.CheckBoxText, classes.ToolbarAppendCheckBoxText), Root: classes.CheckBoxRoot }}>
                Проведение техосмотров
              </CheckboxInput>
            </React.Fragment>
          }
          toolbarButtonText={'Создать заявление'}
          columns={[
            {title: 'Наименование организации', field: 'name', align: 'center'},
            {title: 'ИНН', field: 'inn', align: 'center'},
            {title: 'ОРГН', field: 'orgn', align: 'center'},
            {title: 'Таксопарк', field: 'taksopark', render: rowData => <CheckboxInput />, align: 'center'},
            {title: 'Мед.осмотр', field: 'medosmotr', render: rowData => <CheckboxInput />, align: 'center'},
            {title: 'Тех.осмотр', field: 'tehosmotr', render: rowData => <CheckboxInput />, align: 'center'},
          ]}
        />

        <div className={classes.BodyBottom}>
          <Button text={'Обновить'} size={'big'} />
        </div>
      </LkBody>
    </Lk>
  )
}