import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Modal from '../Modal'
import Button from '../Button'
import {colors} from '../../styles/colors'
import {getClasses} from "../../helpers/mergeStylesHelper";
import EmployeeCard from '../EmployeeCard'

const styles = {
  Photo: {
    width: 165,
    height: 165,
    objectFit: "contain",
    marginBottom: "20px",
  },
  Buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  BottomButtons: {
    marginTop: 20
  }
}

const modalStyles = {
  Body: {
    width: "873px",
    height: "auto",
    backgroundColor: colors.gray7,
  },
  Content: {
    display: "flex",
    flexWrap: 'wrap',
    flexDirection: "column",
    width: "100%"
  },
  Header: {color: colors.gray2},
  Buttons: {
    width: "100%",
  }
}

const useStyles = makeStyles(styles)
const useModalStyles = makeStyles(modalStyles)

export default function EmployeeCardModal(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)
  const modalClasses = useModalStyles()

  return (
    <Modal
      classes={modalClasses}
      headerTextSlot={
        <span>{ props.isCreate ? 'Создание сотрудника' : 'Карточка сотрудника'}</span>
      }
      contentSlot={
        <EmployeeCard showOrganization={props.isCreate} />
      }
      buttonsSlot={
        <div className={classes.Buttons}>
          <Button
            text="Сохранить"
            color="blue"
            size="big"
            className={classes.BottomButtons}
          />

          <Button
            text="Отмена"
            color="red"
            size="big"
            className={classes.BottomButtons}
          />
        </div>
      }
    />
  )
}