import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Modal from '../Modal'
import TextField from '../TextField'
import Button from '../Button'
import {colors} from '../../styles/colors'
import {fontFamily} from '../../styles/font-family'
import {fontSizes} from '../../styles/font-sizes'

const styles = {
  OkButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  CheckMoreCodeLink: {
    fontFamily: fontFamily.medium,
    fontSize: fontSizes.default,
    color: colors.gray2
  }
}

const modalStyles = {
  Body: {
    width: "555px",
    height: "546px",
    backgroundColor: colors.gray7,
  },
  Header: { color: colors.gray2 }
}

const buttonStyles = {
  Root: {
    marginBottom: 55
  }
}

export default function CheckCodeModal(props) {
  const classes = makeStyles(styles)()
  const modalClasses = makeStyles(modalStyles)()
  const buttonClasses = makeStyles(buttonStyles)()

  return (
    <Modal
      classes={modalClasses}
      headerTextSlot={
        <span> Введите полученный проверочный код </span>
      }
      contentSlot={
        <React.Fragment>
          <TextField
            type="text"
            inputId="input-check-code"
            width="392px"
            centered
            required
          />
        </ React.Fragment>
      }
      buttonsSlot={
        <div className={classes.OkButtons}>
          <Button
            text="Сохранить"
            color="red"
            size="big"
            classes={buttonClasses}
          />
          <a className={classes.CheckMoreCodeLink} href="#some">
            Запросить еще один код
          </a>
        </div>
      }
    />
  )
}