import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Modal from '../Modal'
import TextField from '../TextField'
import Button from '../Button'
import {colors} from '../../styles/colors'
import {fontSizes} from '../../styles/font-sizes'
import Select from '../Select'

const modalStyles = {
  Body: {
    width: "873px",
    height: "702px",
    backgroundColor: colors.gray7,
    fontSize: fontSizes.small3,
  },
  Content: {
    width: "710px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between"
  }
}

const inputStyles = {
  select: {
    marginBottom: 30
  },
  input: {
    marginBottom: 30
  }
}

const bigInputStyles = {
  ...inputStyles,
  root: {
    width: 376
  }
}

const mediumInputStyles = {
  ...inputStyles,
  root: {
    width: 320
  }
}

const smallInputStyles = {
  ...inputStyles,
  root: {
    width: 229
  }
}

export default function NewPasswordModal() {
  const modalClasses = makeStyles(modalStyles)()
  const bigInputClasses = makeStyles(bigInputStyles)()
  const mediumInputClasses = makeStyles(mediumInputStyles)()
  const smallInputClasses = makeStyles(smallInputStyles)()

  return (
    <Modal
      classes={modalClasses}
      headerTextSlot={
        <span>Данные транспортного средства</span>
      }
      contentSlot={
        <React.Fragment>
          <Select
            inputId="car-data-modal__type"
            classes={smallInputClasses}
            label="Марка"
          />

          <Select
            inputId="select-model"
            classes={smallInputClasses}
            label="Модель"
          />

          <TextField
            inputId="reg-number"
            classes={smallInputClasses}
            label="Регистрационный номер"
          />

          <TextField
            inputId="reg-card-number"
            classes={bigInputClasses}
            label={'Номер диагностической карты'}
          />

          <TextField
            type="date"
            input-id="reg-card-due-date"
            classes={mediumInputClasses}
            label="Срок действия диагностической карты"
          />

          <TextField
            type="date"
            input-id="reg-insurance-number"
            classes={bigInputClasses}
            label="Номер страховки"
          />

          <TextField
            type="date"
            input-id="reg-insurance-due-date"
            classes={mediumInputClasses}
            label="Срок действия страховки"
          />
        </ React.Fragment>
      }
      buttonsSlot={
        <Button
          text="Сохранить"
          color="red"
          size="big"
        />
      }
    />
  )
}