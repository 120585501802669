import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {colors} from '../../../styles/colors'
import {fontSizes} from '../../../styles/font-sizes'
import TextField from '../../TextField'
import LoginFormEsiaEnter from './LoginFormEsiaEnter'
import Button from '../../Button'
import clsx from 'clsx'
import {AuthFormStyles, AuthFormStylesTextFields} from '../../../styles/sharable'

const useStyles = makeStyles({
  ...AuthFormStyles,

  Body: {
    ...AuthFormStyles.Body,
    marginBottom: 25
  },
  Links: {
    marginTop: "28px",
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  Link: {
    fontSize: fontSizes.small,
    display: "flex",
    justifyContent: "space-between",
  },
  LinkRegistration: {
    color: colors.white
  },
  LinkPassword: {
    color: colors.darkorange
  }
})

const buttonStyles = {
  Root: {
    marginTop: 20
  }
}

const useTextFieldStyles = makeStyles({
  ...AuthFormStylesTextFields,
  last: { marginTop: 20 }
})
const useButtonStyles = makeStyles(buttonStyles)

export default function LoginForm(props) {
  const classes = useStyles(props)
  const textFieldClasses = useTextFieldStyles(props)
  const buttonClasses = useButtonStyles(props)

  return (
    <div className={classes.Root}>
      <form className={classes.Body}>
        <TextField
          name="Телефон"
          label="Телефон"
          type="tel"
          classes={textFieldClasses}
          inputId="login-tel"
          required
        />

        <TextField
          label="Пароль"
          classes={textFieldClasses}
          className={textFieldClasses.last}
          type="password"
          inputId="login-password"
          required
        />

        <Button
          classes={buttonClasses}
          color="orange"
          size={'max'}
          text="Вход"
        />

        <div className={classes.Links}>
          <a href="#reg" className={clsx(classes.Link, classes.LinkRegistration)}>
            Регистрация
          </a>

          <a href="#res" className={clsx(classes.Link, classes.LinkPassword)}>
            Восстановить пароль
          </a>
        </div>
      </form>

      <LoginFormEsiaEnter />
    </div>
  )
}
