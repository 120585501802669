import React from 'react'
import { colors } from '../../styles/colors'
import { fontFamily } from '../../styles/font-family'
import { media } from '../../styles/media'
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles'
import SideLine from '../SideLine'
import {getClasses} from '../../helpers/mergeStylesHelper'

const styles = {
  Root: {
    width: '100%'
  },
  Body: {
    height: 90,
    display: 'flex',
    justifyContent: 'space-between',
    background: 'rgba(0, 0, 0, 0.8)',
    [media['500']]: {
      flexDirection: 'column',
    }
  },
  BodyTransparent: {
    background: 'transparent'
  },
  BodyBlock: {
    width: '33%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    [media['500']]: {
      justifyContent: 'center',
      width: '100%'
    }
  },
  BodyLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  BodyCenter: {
    color: colors.white,
    flexDirection: 'column',
  },
  CompanyName: {
    fontFamily: fontFamily.bold,
    fontSize: '16px'
  },
  Year: {
    fontFamily: fontFamily.light,
    fontSize: '13px'
  },
  BodyRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  Prepend: {
    display: 'flex',
    justifyContent: 'center'
  }
}

const useStyles = makeStyles(theme => styles)

export default function Footer(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)

  return (
    <footer className={classes.Root}>
      <div className={classes.Prepend}>
        {props.prependSlot}
      </div>

      {
        !props.withoutLine &&
        <SideLine classes={props.sideLineClasses || ''} bottom transparent={props.transparent} />
      }

      <div className={clsx(classes.Body, {[classes.BodyTransparent]: props.transparent})}>
        <div className={clsx(classes.BodyLeft, classes.BodyBlock)}>
          {props.leftSlot}
        </div>

        <div className={clsx(classes.BodyCenter, classes.BodyBlock)}>
          <div className={classes.CompanyName}>
            АИС "Таксомотор"
          </div>

          <div className={classes.Year}>
            (C) 2020
          </div>
        </div>

        <div className={clsx(classes.BodyRight, classes.BodyBlock)}>
          {props.rightSlot}
        </div>
      </div>
    </footer>
  )
}
