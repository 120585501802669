import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {colors} from '../../styles/colors'
import LkBodyTabs from './LkBodyTabs'
import {getClasses} from '../../helpers/mergeStylesHelper'
import clsx from 'clsx'

const styles = {
  Root: {
    marginTop: "80px",
    boxShadow: `0 2px 2px 0 ${colors.gray11}`,
    borderRadius: "10px",
    background: colors.gray7,
    width: "calc(100% - 90px)",
    position: "relative",
    padding: 40
  }
}
const useStyles = makeStyles(theme => styles)

export default function LkBody(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)

  return (
    <div className={clsx(classes.Root, props.className)}>
      {props.activeTab && <LkBodyTabs activeTab={props.activeTab} type={props.type} />}
      {props.children}
    </div>
  )
}