import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {getClasses} from '../../helpers/mergeStylesHelper'
import {colors} from '../../styles/colors'
import {fontFamily} from '../../styles/font-family'
import clsx from 'clsx'

const styles = {
  root: {
    padding: 15,
    borderRadius: 10,
    background: colors.white,
    margin: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItemst: 'center',
    flexDirection: 'column'
  },
  gray: {
    background: colors.gray7
  },
  title: {
    size: "14px",
    fontFamily: fontFamily.bold,
    paddingBottom: 14
  },
}

const useStyles = makeStyles(styles)

export default function LkOperotarAnaliticsBlock(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)

  return (
    <div className={clsx(
      classes.root,
      {
        [classes.gray]: props.gray
      },
      props.className
      )
    }>
      {props.title &&
        <div className={classes.title}>
          {props.title}
        </div>
      }

      {props.children}
    </div>
  )
}