import React from 'react';
import { colors } from '../styles/colors';
import {makeStyles} from "@material-ui/core/styles";
import {getClasses} from '../helpers/mergeStylesHelper'

const styles = {
  Root: {
    background: colors.orange,
    width: 21,
    height: 21,
    fontSize: 12,
    marginRight: 15,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: colors.black,
    border: `3px solid ${colors.white}`,
    boxShadow: `0 2px 4px 0 ${colors.borderShadow}`
  }
}

const useStyles =  makeStyles(theme => styles);

export default function Counter(props) {
  const classes = getClasses(props.classes, useStyles(props), styles)

  return (
    <span className={classes.Root}>
      { props.value }
    </span>
  )
}

