import React from 'react';
import MaterialTable, {MTableToolbar} from 'material-table'
import Grid from "@material-ui/core/Grid";
import Button from './Button'
import {InputCommon, tableRowStyle} from '../styles/sharable'
import {makeStyles} from "@material-ui/core/styles";
import { colors } from '../styles/colors'
import {ReactComponent as SearchIcon} from '../assets/icons/search.svg'
import {fontFamily} from '../styles/font-family'
import clsx from 'clsx'


const useStyles = makeStyles({
  Container: (props) => ({
    '& table': {
      borderCollapse: 'separate'
    },
    '& th': {
      textAlign: 'center',
      borderRight: `2px solid ${colors.gray12}`,
      borderBottom: `2px solid ${colors.gray12}`,
      '&:last-child': {
        borderRight: 'none'
      },
      '& span': {
        fontFamily: fontFamily.bold,
        '& span': {
          fontFamily: 'Material Icons'
        }
      },
    },
    '& [class*="-spacer-"]': {
      display: 'none'
    },
    '& .MuiToolbar-root': {
      width: '100%',
      paddingRight: 0,
      paddingLeft: 0,
      '& .MuiFormControl-root': {
        width: props.topToolbarSearchWidth || 450,
        '& .MuiInputBase-root': {
          '& .MuiInputAdornment-root': {
            margin: '0 10px'
          },
          '& input': {
            paddingTop: 10
          },
          '&:before, &:after': {
            border: 'none'
          }
        }
      },
    },
  }),
  TopToolbarWrapper: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 30
  },
  TopToolbarAppend: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 30
  }
});

const searchFieldStyle = {
  background: colors.white,
  padding: '0 10px',
  paddingRight: 0
}

export default function EntitiyList(props) {
  const
    classes = useStyles(props),
    {
      data,
      columns,
      toolbarTopAppend,
      toolbarButtonText,
      toolbarAppend,
      toolbarAppendClassName,
      toolbarLeftButtonClassName,
      addButtonVisibility = true,
      toolbar
    } = props

  const ToolbarAppend = (props) => {
    return (
      <Grid container direction="row" className={clsx(classes.TopToolbarAppend, toolbarAppendClassName)}>
        {toolbarAppend}
      </Grid>
    )
  }

  const getToolbar = (props) => {
    return (
      <React.Fragment>
        <Grid container direction="row" className={classes.TopToolbarWrapper}>
          {addButtonVisibility && <Button text={toolbarButtonText || 'Добавить запись'} size={'big'} className={toolbarLeftButtonClassName} />}
          <MTableToolbar {...props} />
          {toolbarTopAppend}
        </Grid>

        {toolbarAppend && <ToolbarAppend />}
      </React.Fragment>
    )
  }

  return (
    <MaterialTable
      title=''
      columns={columns}
      icons={{ Search: () => <SearchIcon /> }}
      data={data}
      options={{
        actionsColumnIndex: -1,
        showFirstLastPageButtons: false,
        pageSize: 10,
        pageSizeOptions: [10, 20, 30],
        rowStyle: tableRowStyle,
        draggable: false,
        searchFieldStyle: {
          ...InputCommon,
          ...searchFieldStyle
        }
      }}
      components={{
        Toolbar: toolbar || getToolbar,
        Container: props => (
          <div className={classes.Container} {...props}>
            {props.children}
          </div>
        ),
      }}
    />
  )
}
