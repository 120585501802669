import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {fontSizes} from '../../styles/font-sizes'
import {fontFamily} from '../../styles/font-family'

const styles = {
  Root: {
    fontSize: fontSizes.small,
  },
  ValueSelect: {
    border: "none",
    background: "transparent",
    fontFamily: fontFamily.bold
  }
}
const useStyles = makeStyles(theme => styles)

export default function LkSorting(props) {
  const classes = useStyles(props)

  const defaultOptions = [
    {value: 'date', name: 'Дате'},
    {value: 'type', name: 'Типу'},
  ]

  return (
    <div className={classes.Root}>
      <label htmlFor="lk-sort">
        Сортировать по
      </label>
      <select className={classes.ValueSelect} id="lk-sort">
        {(props.options || defaultOptions).map(option =>
          <option value={option.value}>
            { option.name }
          </option>
        )}
      </select>
    </div>
  )
}