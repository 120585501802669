import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Lk from '../../lk/Lk'
import LkHeader from '../../lk/LkHeader'
import LkBody from '../../lk/LkBody'
import LkHeaderAppend from '../../lk/LkHeaderAppend'
import {SlideArrow} from '../../../styles/sharable'
import {ReactComponent as RightArrowIcon} from '../../../assets/icons/arrow-right.svg'
import {ReactComponent as LeftArrowIcon} from '../../../assets/icons/arrow-left.svg'
import clsx from 'clsx'
import LkOrganization from './LkOrganization'
import CardAddButton from '../../CardAddButton'
import LkHeaderWorkStatus from '../../lk/LkHeaderWorkStatus'

const styles = {
  Root: {},
  Body: {
    position: "relative",
    padding: "66px",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  OrganizationsBlock: {
    marginTop: "60px",
    position: "relative",
    width: "100%",
    "&:first-child": {marginTop: "0"}
  },
  Organization: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
}

const addButtonStyles = {
  Text: {
    textAlign: "left", paddingLeft: "30px"
  }
}

const slideArrowStyles = {
  ...SlideArrow,
  Left: {
    ...SlideArrow.Left,
    left: "-60px",
  },
  Right: {
    ...SlideArrow.Right,
    right: "-60px"
  }
}
const useStyles = makeStyles(theme => styles)

export default function LkTravelList(props) {
  const classes = useStyles(props)
  const addButtonClasses = makeStyles(addButtonStyles)()
  const slideArrowClasses = makeStyles(slideArrowStyles)()

  const organizations = {
    med: [
      {
        title: 'Открытая поликлиника',
        name: 'Предрейсовый медосмотр',
        icon: require('../../../assets/icons/med.svg'),
        responsible: 'Ответственный: Иванов И.И.',
        date: '01.07.2020 09:01',
        status: 'progress'
      },
      {
        title: 'Открытая поликлиника 2',
        name: 'Предрейсовый медосмотр',
        icon: require('../../../assets/icons/med.svg'),
        responsible: 'Ответственный: Крюков Ж.Б.',
        date: '01.05.2020 09:01',
        status: 'completed'
      }
    ],
    to: [
      {
        title: 'Прайм моторс',
        name: 'Предрейсовое ТО',
        icon: require('../../../assets/icons/instruments.svg'),
        date: '01.07.2020 09:03',
        status: 'empty'
      }
    ]
  }

  return (
    <Lk className={classes.Root}>
      <LkHeader append={
        <LkHeaderAppend
          title={'Личный кабинет'}
          backLink={'/some'}
          rightSlot={<LkHeaderWorkStatus type={'calculating'} value={'В обработке'}/>}
        />
      } />

      <LkBody activeTab={'travel'}>
        <div className={classes.Body}>
          <div className={classes.OrganizationsBlock}>
            <button className={clsx(slideArrowClasses.Right, slideArrowClasses.Root)}>
              <RightArrowIcon />
            </button>

            <button className={clsx(slideArrowClasses.Left, slideArrowClasses.Root)}>
              <LeftArrowIcon />
            </button>

            <div className={classes.Organization}>
              { organizations.med.map((organization, index) =>
                <LkOrganization key={index} organization={organization} />
              )}

              <CardAddButton
                classes={addButtonClasses}
                text={'Добавить организацию для прохождения медицинского осмотра'}
              />
            </div>
          </div>

          <div className={classes.OrganizationsBlock}>
            <button className={clsx(slideArrowClasses.Right, slideArrowClasses.Root)}>
              <RightArrowIcon />
            </button>

            <button className={clsx(slideArrowClasses.Left, slideArrowClasses.Root)}>
              <LeftArrowIcon />
            </button>

            <div className={classes.Organization}>
              { organizations.to.map((organization, index) =>
                <LkOrganization organization={organization} key={index} />
              )}

              <CardAddButton
                classes={addButtonClasses}
                text={'Добавить организацию для прохождения контроля технического состояния транспортных средств'}
              />
            </div>
          </div>

        </div>
      </LkBody>
    </Lk>
  )
}