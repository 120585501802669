import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {colors} from '../../styles/colors'
import {fontSizes} from '../../styles/font-sizes'
import Modal from '../Modal'
import Button from '../Button'

const styles = {
    DocWrapper: { maxWidth: "730px", maxHeight: "844px" },
    Doc: { fontSize: fontSizes.small2, lineHeight: "18px" },
}

const buttonStyles = {
  Root: { width: "325px" }
}

const modalStyles = {
  Body: {
    width: "883px",
    height: "auto",
    backgroundColor: colors.gray7,
  },
  Header: { color: colors.gray2 },
}

export default function TermsModal(props) {
  const classes = makeStyles(styles)()
  const buttonClasses = makeStyles(buttonStyles)()
  const modalClasses = makeStyles(modalStyles)()

  return (
    <Modal
      classes={modalClasses}
      headerTextSlot={
        <span> Пользовательское соглашение </span>
      }
      contentSlot={
        <div className={classes.DocWrapper}>
          <div className={classes.Doc}>
            <p>
              1. Общие положения Пользовательского соглашения
            </p>
            <p>
              1.1. В настоящем документе и вытекающих или связанным с ним отношениях Сторон применяются следующие
              термины и определения:
            </p>
            <p>
              а) Платформа — программно-аппаратные средства, интегрированные с Сайтом Администрации;
            </p>
            <p>
              б) Пользователь — дееспособное физическое лицо, присоединившееся к настоящему Соглашению в собственном
              интересе либо выступающее от имени и в интересах представляемого им юридического лица. в) Сайт
              Администрации/ Сайт — интернет-сайты, размещенные в домене ________.ru и его поддоменах.
            </p>
            <p>
              г) Сервис — комплекс услуг и лицензия, предоставляемые Пользователю с использованием Платформы.
            </p>
            <p>
              д) Соглашение - настоящее соглашение со всеми дополнениями и изменениями.
            </p>
            <p>
              1.2. Использование вами Сервиса любым способом и в любой форме в пределах его объявленных функциональных
              возможностей, включая:
            </p>
            <p>
              просмотр размещенных на Сайте материалов;
            </p>
            <p>
              регистрация и/или авторизация на Сайте, размещение или отображение на Сайте любых материалов, включая но
              не ограничиваясь такими как: тексты, гипертекстовые ссылки, изображения, аудио и видео- файлы, сведения
              и/или иная информация, создает договор на условиях настоящего Соглашения в соответствии с положениями
              ст.437 и 438 Гражданского кодекса Российской Федерации.
            </p>
            <p>
              1.3. Воспользовавшись любой из указанных выше возможностей по использованию Сервиса вы подтверждаете, что:
            </p>
            <p>
              а) Ознакомились с условиями настоящего Соглашения в полном объеме до начала использования Сервиса.
            </p>
            <p>
              б) Принимаете все условия настоящего Соглашения в полном объеме без каких-либо изъятий и ограничений с
              вашей стороны и обязуетесь их соблюдать или прекратить использование Сервиса. Если вы не согласны с
              условиями настоящего Соглашения или не имеете права на заключение договора на их основе, вам следует
              незамедлительно прекратить любое использование Сервиса.
            </p>
            <p>
              в) Соглашение (в том числе любая из его частей) может быть изменено Администрацией без какого-либо
              специального уведомления. Новая редакция Соглашения вступает в силу с момента ее размещения на Сайте
              Администрации либо доведения до сведения Пользователя в иной удобной форме, если иное не предусмотрено
              новой редакцией Соглашения.
            </p>
            <p>
              Важно знать! Для придания юридической силы дисклаймеру, включенному в текст соглашения с пользователем,
              необходимо подтвердить факт ознакомления и принятия пользователем его условий. Для этого используется
              предусмотренный законодательством механизм заключения договоров.
            </p>
            <p>
              2. Условия пользования по Соглашению
            </p>
            <p>
              2.1. Использование функциональных возможностей Сервиса допускается только после прохождения Пользователем
              регистрации и авторизации на Сайте в соответствии с установленной Администрацией процедурой.
            </p>
          </div>
        </div>
      }
      buttonsSlot={
        <Button
          text="Ок"
          color="red"
          size="big"
          classes={buttonClasses}
        />
      }
    />
  )
}