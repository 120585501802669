import React from 'react'
import { colors } from '../../styles/colors'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as MoscowTransportLogoIcon } from '../../assets/icons/moscow_transport.svg'
import { ReactComponent as CoddLogoIcon } from '../../assets/icons/codd_logo.svg'

const useStyles =  makeStyles(theme => ({
  FooterLogos: {
    height: 100,
    width: 300,
    display: 'flex',
    justifyContent: 'center',
    marginTop: 50
  },
  Item: {
    margin: '0 50px'
  },
  Divider: {
    height: 50,
    width: 2,
    background: colors.white
  }
}));

export default function FooterLogos(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.FooterLogos}>
      <MoscowTransportLogoIcon className={classes.Item} />

      <div className={classes.Divider} />

      <CoddLogoIcon className={classes.Item} />
    </div>
  )
}
